// All the code in this module is
// enclosed in closure
(function(exports) {

    // Data object models that can be used in both client and server

    // Product
    var PRODUCT_STATUS = {
        LIVE: 'LIVE',
        DRAFT: 'DRAFT',
        DISCONTINUED: 'DISCONTINUED'
    }

    var SUPPLIER_TYPE = {
        GARMENT: 'Garment',
        PRINTER: 'Printer'
    }

    var PROMO_PRINT_NAME = {
        SCREEN_PRINT: 'Screen Print',
        PAD_PRINT: 'Pad Print',
        LASER: 'Laser',
        LASER_PLUS: 'Laser Plus',
        DEBOSS: 'Deboss',
        EMBROIDERY: 'Embroidery',
        // DIGITAL: 'Digital Colour Print',
        // DIGITAL_TRANSFER: 'DFT',
        SUBLIMATION: 'Sublimation'
    }

    var PROMO_PRODUCT_RUNCHARGE_TYPE = {
        STEP: 'step',
        PER_COLOUR: 'perColour',
        STATIC: 'static',
    }


    var PRINT_TYPES = {
        SCREEN_PRINT: 'Screen Print',
        DTG: 'DTG',
        DFT: 'DFT',
        VINYL: 'Vinyl',
        EMBROIDERY: 'Embroidery'
    }

    var OTHER_SERVICES = {
        PRESSING: 'Pressing',
        SEWING: 'Sewing'
    }

    var SPECIALTY_PRINTS = {
        APPLIQUE: 'Applique',
        SUBLIMATED_PATCH: 'Sublimated Patch',
        WOVEN_PATCH: 'Woven Patch',
        WOVEN_LABEL: 'Woven Label',
        EMBROIDERED_PATCH: 'Embroidered Patch',
        LEATHER_PATCH: 'Leather Patch',
        LEATHER_LABEL: 'Leather Label',
        PUFF_EMBROIDERY: 'Puff Embroidery',
        SPOT_PROCESS: 'Spot Process',
        PUFF_PRINT: "Puff",
    }

    var PRINT_GROUPS = {
        SCREEN_PRINT: {
            key: PRINT_TYPES.SCREEN_PRINT,
            list: [
                PRINT_TYPES.SCREEN_PRINT,
                SPECIALTY_PRINTS.PUFF_PRINT,
                SPECIALTY_PRINTS.SPOT_PROCESS
            ]
        },
        EMBROIDERY: {
            key: PRINT_TYPES.EMBROIDERY,
            hasCustomizations: true,
            list: [
                PRINT_TYPES.EMBROIDERY,
                SPECIALTY_PRINTS.PUFF_EMBROIDERY,
                SPECIALTY_PRINTS.APPLIQUE
            ]
        },
        DFT: {
            key: PRINT_TYPES.DFT,
            hasCustomizations: true,
            list: [
                PRINT_TYPES.DFT
            ]
        },
        SEWING: {
            key: OTHER_SERVICES.SEWING,
            individualTagging: true,
            list: [
                SPECIALTY_PRINTS.WOVEN_LABEL,
                SPECIALTY_PRINTS.LEATHER_LABEL,
            ]
        },
        PATCH_APPLICATION: {
            key: 'Patch Application',
            list: [
                SPECIALTY_PRINTS.SUBLIMATED_PATCH,
                SPECIALTY_PRINTS.WOVEN_PATCH,
                SPECIALTY_PRINTS.EMBROIDERED_PATCH,
                SPECIALTY_PRINTS.LEATHER_PATCH,
            ]
        },
        IN_HOUSE: {
            key: "In House",
            list: [],
            hasCustomizations: true,
            individualTagging: true
        },
        OUTSOURCED: {
            key: "Outsourced",
            isOutsourced: true,
            list: [],
        }
    }

    var ALL_PRODUCT_TYPES = {
        CLOTHING: 'Clothing',
        PROMO_PRODUCT: 'Promotional Product',
        STICKER: 'Sticker',
        PATCH: 'Patch'
    }

    // Admin
    var ADMIN_TYPES = {
        CSR:'CSR', // external
        PRODUCTION: 'PRODUCTION',
        WAREHOUSE: 'WAREHOUSE',
        ORDERER: 'ORDERER',
        PURCHASER: 'PURCHASER',
        SETUP: 'SETUP', // external
        ADMIN: 'ADMIN',
        SYSTEM: 'SYSTEM',
        MARKETING: 'MARKETING',
        DATA_ENTRY: 'DATAENTRY', // external
        ACCOUNTING: 'ACCOUNTING' // external
    };

    // Page Templates
    var PAGE_TEMPLATE_STATUS = {
        LIVE: 'LIVE',
        APPROVAL: 'APPROVAL',
        DRAFT: 'DRAFT',
    }

    var PAGE_SECTIONS = {
        BANNER: 'Banner',
        LEFT_ALIGNED_TEXT: 'LeftAlignedText',
        LEFT_ALIGNED_IMAGE: 'LeftAlignedImage',
        PRODUCTS: 'Products',
        FEATURED_PRODUCTS: 'FeaturedProducts',
        FAQ: 'FAQ',
        VERTICAL_ITEM_LIST: 'VerticalItemList',
        IMAGE_ROW: 'ImageRow',
        LEFT_COLUMNED: 'LeftColumn',
        RIGHT_COLUMNED: 'RightColumn',
        POPULAR_CATEGORIES: 'PopularCategories',
        OUR_COMPASS: 'OurCompass',
        TURNAROUND_PROPOSITION: 'TurnaroundProp',
        VALUE_PROPOSITIONS:  'ValuePropositions',
        EXPERT_DESIGN_REVIEW: 'ExpertDesignReview',
        SHIPPING_VALUE_PROP: 'ShippingValue',
        MERCH_VALUE_PROP: 'Merch',
        BRANDS_OFFERED: 'Brands',
        CLIENTS: 'Clients',
        REVIEWS: 'Reviews',
        MAP: 'Map',
        LOCATION: 'Location'
    }

    var PAGE_DATA_TYPES = {
        TEXT: 'TEXT',
        RICH_TEXT: 'RICH_TEXT',
        IMAGE: 'IMAGE',
        BOOLEAN: 'BOOLEAN',
        PRODUCT: 'PRODUCT',
        PRODUCT_SKU: 'PRODUCT_SKU',
        LIST: 'LIST',
    }

    var URL_TYPES = {
        PAGE_TEMPLATE_REF: 'PAGE_TEMPLATE',
        HYPERLINK: 'HYPER_LINK'
    }

    var PAGE_TEMPLATE_LOCATION_TOKENS = {
        LOCATION_CITY: 'LCITY',
        LOCATION_STATE: 'LSTATE'
    }

    var PAGE_TEMPLATE_URL_LOCATION_SEPARATORS = {
        IN: '-in-',
        SLASH: '/'
    }

    // Marketing Types
    var MARKETING_TYPES = {
        SEARCHENGINE: 'Search Engine (Google, Yahoo, etc)',
        RECOMMENDATIONBYFRIEND: 'Recommended by friend or colleague',
        INSTAGRAM: 'Instagram',
        LINKEDIN: 'LinkedIn',
        FACEBOOK: 'Facebook',
        BLOG: 'Blog or Publication',
        OTHER: 'Other'
    }

    // Shipment Types
    var SHIPMENT_MODAL_STATES = {
        CUSTOM: 'CUSTOM', // 1111 shipments
        SHIP: 'SHIP', // regular ship orders
        WAREHOUSE: 'WAREHOUSE', // warehouse shipments (internal)
        SPLIT_SHIP: 'SPLIT_SHIP' // split shipment
    }

    var BOX_PAGE_STATES = {
        EDIT: 'EDIT',
        VIEW: 'VIEW',
        SELECT: 'SELECT'
    }

    var ITEM_COMPACT_VIEW_STATES = {
        EDIT: 'EDIT',
        VIEW: 'VIEW',
        SELECT: 'SELECT'
    }

    var SPLIT_SHIPMENT_PAGE_STATES = {
        ORDER: 'ORDER',
        PRINT: 'PRINT'
    }

    var SHIPMENT_STATUS = {
        PURCHASED: 'PURCHASED',
        REFUNDED: 'REFUNDED',
    }

    var TRACK_SHIPMENT_GRID = {
        BOX: 'BOX',
        SHIPMENT: 'SHIPMENT',
        SPLIT_SHIP: 'SPLIT_SHIP'
    }


    // Customer
    var CUSTOMER_TYPES = {
        BUSINESS: {value :'Business' , deprecated : false},
        CONSTRUCTION: {value :'Construction', deprecated : false},
        GOVERNMENT: {value :'Government', deprecated : false},
        NON_PROFIT: {value :'Non Profit', deprecated : false},
        OTHER: {value :'Other', deprecated : false},
        SCHOOL: {value :'School', deprecated : false},
        SPORT: {value :'Sport', deprecated : false},
        DART: {value :'Dart Jersey', deprecated : true},
        ULTIMATE: {value : 'Ultimate Frisbee Team', deprecated : true},
        PERSONAL: {value: 'Personal Use', deprecated : true},
        GIFT: {value : 'Gift', deprecated : true},
        PROMO: {value :'Promo', deprecated : true}
    }

    // Custom URL link fragments
    // TODO: replace all string usages in code with this model
    var CUSTOM_URL_LINK_FRAGMENTS = {
        T_SHIRT_PRINTING: '/custom-products/custom-clothing/t-shirts/',
        SCREEN_PRINTING: '/print-methods/screen-printing/',
        EMBROIDERY: '/print-methods/embroidery/'
    }

    var SHIPPING_PROVIDERS = {
        UPS: 'UPS',
        USPS: 'USPS',
        CANPAR: 'Canpar',
        CANADA_POST: 'Canada Post',
        PUROLATOR: 'Purolator',
        FED_EX: 'FedEx',
        DHL: 'DHL',
        DICOM: 'Dicom',
        OTHER: 'OTHER'
    }

    var ORDER_CURRENCY = {
        CAD: 'CAD',
        USD: 'USD'
    }

    // locations
    var OFFICE_LOCATIONS = {
        VAN: 'VANCOUVER',
        TOR: 'TORONTO'
    }

    // shipment boxes
    var SHIP_BOX_SIZES = {
        BAG: 'BAG',
        S: 'SMALL',
        M: 'MEDIUM',
        L: 'LARGE',
        XL: 'XLARGE',
        SWAG_BOX: 'SWAGBOX',
        CUSTOM: 'CUSTOM'
    }

    //product list sort types
    var PAYMENT_TYPES = {
        CREDITCARD: 'Credit Card',
        WIRE: 'Wire Transfer',
        ETRANSFER: 'Etransfer',
        CHEQUE : 'Cheque'
    }


    //product list sort types
    var PRODUCT_SORT_TYPES = {
        RELEVANCE: 'Relevance',
        PRICE_ASC: 'Price Low to High',
        PRICE_DESC: 'Price High to Low',
        CUSTOM: 'Custom',
    }

    //Order
    var ORDER_STATUSES = {
        QUOTING: "Quoting",
        CREATING_MOCKUP: "Creating Mockup",
        PENDING_APPROVAL: "Pending Mockup Approval",
        PRINTING: "Printing",
        SHIPPED: "Shipped",
        ARCHIVED: "Archived",
        DRAFT: "Draft"
    }

    var ORDER_CREATION_TYPES = {
        ADMIN_ORDER: "Web Order",
        INTERNAL_ORDER: "Internal Order",
        LIGHTNING_ORDER: "Minimum Order"
    }

    var ORDER_SHIPPING_TYPES = {
        PICK_UP: "Pick Up",
        SHIP: "Ship",
        SPLIT_SHIP: "Split Ship"
    }

    var PRODUCT_TAGS = {
        CORPORATE: 'Corporate',
        ECO_FRIENDLY: 'Eco Friendly',
        MADE_IN_CANADA: 'Made in Canada',
        MOST_POPULAR: 'Most Popular',
        WORK_WEAR: 'Work Wear',
        PERFORMANCE: 'Performance',
        BUDGET_FRIENDLY: 'Budget Friendly',
        QUICK_DELIVERY: 'Quick Delivery',
        PREMIUM: 'Premium',
        STAFF_PICK: 'Staff Pick'
    }

    var PROMO_PRODUCT_DISCOUNT_CODES = {
        NET: 'Net',

        A: 'A',
        B: 'B',
        C: 'C',
        D: 'D',
        E: 'E',
        F: 'F',
        G: 'G',
        H: 'H',
        I: 'I',

        P: 'P',
        Q: 'Q',
        R: 'R',
        S: 'S',
        T: 'T',
        U: 'U',
        V: 'V',
        W: 'W',
        X: 'X'
    }

    // Admin Tasks

    var MY_TASKS_SORT = {
        DUE_NEW: 'New',
        OVERDUE: 'Overdue',
        ORDER_VALUE: 'Order Value',
        CUSTOMER_VALUE: 'Customer Paid Value',
        COMPLETED: 'Completed Date',
    }

    var TASK_TYPES = {
        FOLLOW_UP_PROOF: 'Mockup Follow-up',
        FOLLOW_UP_QUOTE: 'Quote Follow-up',
        ASK_FOR_REVIEW: 'Review/Referral Request',
        TOUCH_BASE: 'Touch Base'
    }

    var TASK_STATUS = {
        COMPLETE: 'Completed',
        ARCHIVED: 'Archived'
    }

    var DASHBOARD_DATE_FILTERS = {
        THIS_MONTH: {
            name: 'This Month',
            period: 'month'
        },
        LAST_MONTH: {
            name: 'Last Month',
            period: 'month'
        },
        TWO_MONTHS_AGO: {
            name: '2 Months Ago',
            monthsBack: 2,
            period: 'month'
        },
        THREE_MONTHS_AGO: {
            name: '3 Months Ago',
            monthsBack: 3,
            period: 'month'
        },
        FOUR_MONTHS_AGO: {
            name: '4 Months Ago',
            monthsBack: 4,
            period: 'month'
        },
        FIVE_MONTHS_AGO: {
            name: '5 Months Ago',
            monthsBack: 5,
            period: 'month'
        },
        SIX_MONTHS_AGO: {
            name: '6 Months Ago',
            monthsBack: 6,
            period: 'month'
        },
        LAST_YEAR: {
            name: 'Last Year',
            period: 'year'
        },
        LAST_7_DAYS: {
            name: 'Last 7 Days'
        },
        LAST_30_DAYS: {
            name: 'Last 30 Days'
        },
        LAST_90_DAYS: {
            name: 'Last 90 Days'
        },
        TODAY: {
            name: 'Today'
        },
        YESTERDAY: {
            name: 'Yesterday'
        },
        SAME_DAY_LAST_YEAR: {
            name: 'Last Year - Today'
        },
        YESTERDAY_LAST_YEAR: {
            name: 'Last Year - Yesterday'
        },
        THIS_MONTH_LAST_YEAR: {
            name: 'Last Year - This Month',
            period: 'month'
        },
        CUSTOM_RANGE: {
            name: 'Custom'
        }
    }

    var DASHBOARD_DRILLDOWN_OPTIONS = {
        CSR: 'CSR',
        CUSTOMER_TYPE: 'Customer Type',
        COUNTRY: 'Country',
        STATE: 'State',
        SHIP_TYPE: 'Shipping Type',
        // Line Item Drilldown
        PRODUCT_TYPE: 'Product Type',
        PRODUCT_CATEGORY: 'Product Category',
        PRODUCT_SKU: 'Product SKU',
        PRODUCT_BASE_PRICE: 'Garment Base Price',
        GARMENT_QUANTITY: 'Garment Quantity',
        GARMENT_SUPPLIER: 'Garment Supplier',
        PRINT_SUPPLIER: 'Print Supplier',
        // Print drilldown,
        PRINT_TYPE: 'Print Type',
    }

    var DASHBOARD_GARMENT_QUANTITY_BUCKET = {
        ONE_FIVE: '1 - 5',
        FIVE_TEN: '5 - 10',
        TEN_TWENTYFIVE: '10 - 25',
        TWENTYFIVE_FIFTY: '25 - 50',
        FIFTY_HUNDRED: '50 - 100',
        HUNDRED_TWOHUNDRED: '100 - 200',
        TWOHUNDRED_FIVEHUNDRED: '200 - 500',
        FIVEHUNDRED_THOUSAND: '500 - 1000',
        OVER_ONE_THOUSAND: '1000+',
        UNCATEGORIZED: 'Other'
    }

    var DASHBOARD_PRODUCT_BASE_PRICE_BUCKET = {
        ZERO_TWO: '0 - $2',
        TWO_FIVE: '2 - $5',
        FIVE_TWENTY: '5 - $20',
        TWENTY_THIRTY: '20 - $30',
        THIRTY_FOURTY: '30 - $40',
        FOURTY_FIFTY: '40 - $50',
        FIFTY_SEVENTY: '50 - $70',
        SEVENTY_HUNDRED: '70 - $100',
        HUNDRED_ONEFIFTY: '100 - $150',
        OVER_ONEFIFTY: 'Over $150',
        UNCATEGORIZED: 'Other'
    }

    // Export the function to exports
    // In node.js this will be exports
    // the module.exports
    // In browser this will be function in
    // the global object sharedModule
    exports.PRODUCT_STATUSES = PRODUCT_STATUS;
    exports.ALL_PRODUCT_TYPES = ALL_PRODUCT_TYPES;
    exports.PAGE_TEMPLATE_STATUSES = PAGE_TEMPLATE_STATUS;
    exports.ADMIN_TYPES = ADMIN_TYPES;
    exports.PAGE_SECTIONS = PAGE_SECTIONS;
    exports.PAGE_DATA_TYPES = PAGE_DATA_TYPES;
    exports.PAGE_TEMPLATE_LOCATION_TOKENS = PAGE_TEMPLATE_LOCATION_TOKENS;
    exports.PAGE_TEMPLATE_URL_LOCATION_SEPARATORS = PAGE_TEMPLATE_URL_LOCATION_SEPARATORS;
    exports.CUSTOM_URL_LINK_FRAGMENTS = CUSTOM_URL_LINK_FRAGMENTS
    exports.URL_TYPES = URL_TYPES;
    exports.CUSTOMER_TYPES = CUSTOMER_TYPES;
    exports.MARKETING_TYPES = MARKETING_TYPES;
    exports.OFFICE_LOCATIONS = OFFICE_LOCATIONS;
    exports.BOX_PAGE_STATES = BOX_PAGE_STATES;
    exports.SPLIT_SHIPMENT_PAGE_STATES = SPLIT_SHIPMENT_PAGE_STATES;
    exports.SHIP_BOX_SIZES = SHIP_BOX_SIZES;
    exports.SHIPMENT_STATUS = SHIPMENT_STATUS;
    exports.SHIPMENT_MODAL_STATES = SHIPMENT_MODAL_STATES;
    exports.PRODUCT_SORT_TYPES = PRODUCT_SORT_TYPES;
    exports.PAYMENT_TYPES = PAYMENT_TYPES
    exports.ORDER_STATUSES = ORDER_STATUSES;
    exports.ORDER_SHIPPING_TYPES = ORDER_SHIPPING_TYPES;
    exports.TRACK_SHIPMENT_GRID = TRACK_SHIPMENT_GRID;
    exports.PROMO_PRINT_NAME = PROMO_PRINT_NAME;
    exports.PRINT_TYPES = PRINT_TYPES;
    exports.ORDER_CREATION_TYPES = ORDER_CREATION_TYPES;
    exports.ITEM_COMPACT_VIEW_STATES = ITEM_COMPACT_VIEW_STATES;
    exports.SUPPLIER_TYPE = SUPPLIER_TYPE;
    exports.PRODUCT_TAGS = PRODUCT_TAGS;
    exports.PROMO_PRODUCT_DISCOUNT_CODES = PROMO_PRODUCT_DISCOUNT_CODES;
    exports.PROMO_PRODUCT_RUNCHARGE_TYPE = PROMO_PRODUCT_RUNCHARGE_TYPE;
    exports.SHIPPING_PROVIDERS = SHIPPING_PROVIDERS;
    exports.SPECIALTY_PRINTS = SPECIALTY_PRINTS;
    exports.OTHER_SERVICES = OTHER_SERVICES;
    exports.TASK_TYPES = TASK_TYPES;
    exports.TASK_STATUS = TASK_STATUS;
    exports.MY_TASKS_SORT = MY_TASKS_SORT;
    exports.DASHBOARD_DATE_FILTERS = DASHBOARD_DATE_FILTERS;
    exports.DASHBOARD_DRILLDOWN_OPTIONS = DASHBOARD_DRILLDOWN_OPTIONS;
    exports.DASHBOARD_GARMENT_QUANTITY_BUCKET = DASHBOARD_GARMENT_QUANTITY_BUCKET;
    exports.DASHBOARD_PRODUCT_BASE_PRICE_BUCKET = DASHBOARD_PRODUCT_BASE_PRICE_BUCKET;
    exports.PRINT_GROUPS = PRINT_GROUPS;
    exports.ORDER_CURRENCY = ORDER_CURRENCY;


})(typeof exports === 'undefined'?
    this['modelService']={}: exports);