(function () {
    'use strict';

    angular.module('app')
        .factory('CountryService', CountryService)

    CountryService.$inject = ['$http', '$rootScope', '$q', '$location'];

    function CountryService($http, $rootScope, $q, $location) {

        var availableLocalesURLs = ["", 'en-us'];
        var currentLocale;

        function validatePageLocale(forceLocaleCheck, disableRecommendModal) {
            return recommendLocalePopupPromise(forceLocaleCheck)
                .then(function (proposedLocale) {
                    if (proposedLocale !== getCurrentLocale()) {
                        $rootScope.recommendLocale = proposedLocale
                        if (disableRecommendModal) {
                            // ignore
                        } else {
                            $rootScope.showRecommendLocaleModal = true;
                        }
                    }
                })
                .catch(function (e) {
                    //ignore
                })
        }

        function getUserVisitingInfoPromise(forceFetch) {
            var promise;
            var userGeoObject = $rootScope.getLocalStorage("iplocale");
            var refetch = false
            if (forceFetch) {
                refetch = true
            } else if (!userGeoObject) {
                refetch = true
            } else if (userGeoObject.expiry) {
                if (Date.now() >= userGeoObject.expiry) {
                    refetch = true
                }
            }
            if (!userGeoObject || refetch) {
                console.log('refetech')
                promise = $http.post('/country')
                    .then(function (response) {
                        var geoResponse = response.data;
                        var proposedLocale = "" // default ca
                        if (geoResponse.country === 'CA') {
                            proposedLocale = ''
                        } else {
                            proposedLocale = "en-us"
                        }
                        var toSaveGeoObject = {
                            locale: proposedLocale,
                            expiry: Date.now() + (60000 * 60 * 24 * 7),
                            country: geoResponse.country,
                            region: geoResponse.region,
                            city: geoResponse.city,
                        }

                        $rootScope.setLocalStorage("iplocale", JSON.stringify(toSaveGeoObject));
                        return toSaveGeoObject
                    })
                    .catch(function (e) {
                        return $q.reject()
                    })
            } else {
                // console.log(userGeoObject);
                promise = $q.resolve(userGeoObject)
            }
            return promise
        }

        // 1. determine country from url
        // 2. if popup not closed before, geolocate (async) ip and determine best viewing website
        function recommendLocalePopupPromise(forceLocaleCheck) {
            var promise;
            var confirmExpiry = $rootScope.getLocalStorage('userConfirmedLocation');
            if (!forceLocaleCheck && confirmExpiry && Date.now() <= Number(confirmExpiry)) {
                promise = $q.resolve(currentLocale);
            } else {
                promise = getUserVisitingInfoPromise(forceLocaleCheck)
                    .then(function(userGeoObject) {
                        return userGeoObject.locale
                    })
            }

            return promise;
        }

        function isValidLocale(givenLocale) {
            return availableLocalesURLs.includes(givenLocale);
        }

        // meant to run ONCE on initial page load only, if need to set again the page refreshes via shouldReloadApp
        function setLocale(givenLocale, shouldReloadApp) {
            if (!isValidLocale(givenLocale)) {
                throw Error('Invalid Locale ' + givenLocale);
            } else {
                if (currentLocale !== givenLocale) {
                    currentLocale = givenLocale;
                    if (shouldReloadApp) {
                        reloadUrl(true);
                    } else {
                        $rootScope.$broadcast('localeReady');
                    }
                }
            }
        }

        function reloadUrl(shouldHardReload) {
            var newUrl;
            if (currentLocale) {
                var currentPath = $location.url();
                if (currentPath === '/') {
                    currentPath = ''
                }
                newUrl = '/' + currentLocale + currentPath;
            } else {
                // replace first subfolder directory which would be the locale
                newUrl = $location.url().replace(/\/[^\/]+/, '');
            }
            if (shouldHardReload) {
                window.location.href = newUrl; // app reload
            } else {
                // update location (for internal navigation cart -> checkout)
                $location.url(newUrl);
            }
        }

        function getCurrentLocale() {
            return currentLocale;
        }

        function getRelativeLocaleUrl(url) {
            var baseUrl = $location.protocol() + '://' + location.host;
            // absolute url
            var fallbackUrl = 'https://coastalreign.com'

            var returnUrl = url;
            if (!_.isUndefined(currentLocale)) {
                var matchingPath;
                if (url.indexOf(baseUrl) === 0) {
                    matchingPath = url.substring(baseUrl.length);
                } else if (url.indexOf(fallbackUrl) === 0) {
                    matchingPath = url.substring(fallbackUrl.length);
                }
                if (matchingPath) {
                    returnUrl = matchingPath;
                    var pathLocale = ""; // default ca
                    var firstSubdirectory = matchingPath.split("/")[1]
                    if (isValidLocale(firstSubdirectory)) {
                        pathLocale = firstSubdirectory;
                    }
                    if (pathLocale !== currentLocale) {
                        if (matchingPath === '/') {
                            matchingPath = ''
                        }
                        returnUrl = '/' + currentLocale + matchingPath
                    }
                }
            }


            return returnUrl;
        }

        function textFindAndReplace(stringToCheck) {
            var returnString = stringToCheck;
            if (currentLocale === 'en-us') {
                returnString = returnString
                    .replace(new RegExp(/colour/, "g"), 'color')
                    .replace(new RegExp(/Colour/, "g"), 'Color')
                    .replace(new RegExp(/colours/, "g"), 'colors')
                    .replace(new RegExp(/Colours/, "g"), 'Colors')
                    .replace(new RegExp(/\bgrey\b/, "g"), 'gray')
                    .replace(new RegExp(/\bGrey\b/, "g"), 'Gray')
                    .replace(new RegExp(/\blabour\b/, "g"), 'labor')
                    .replace(new RegExp(/\bLabour\b/, "g"), 'Labor')
            }
            return returnString;
        }

        return {
            getUserVisitingInfoPromise: getUserVisitingInfoPromise,
            validatePageLocale: validatePageLocale,
            setLocale: setLocale,
            getRelativeLocaleUrl: getRelativeLocaleUrl,
            isValidLocale: isValidLocale,
            getCurrentLocale: getCurrentLocale,
            reloadUrl: reloadUrl,
            textFindAndReplace: textFindAndReplace
        };
    }

})();
