(function() {
    'use strict';

    angular.module('app')
        .factory('ProductService', ProductService)

    ProductService.$inject = ['$http', '$q'];

    function ProductService($http, $q) {

        function getProductByUrl(url, category) {
            var route = '/api/products/name/' + url;
            if (category) {
                route += '/' + category
            }
            return $http.get(route)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function fetchProducts(query) {
            return $http.post('/api/products/filter', query)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function fuzzySearchProducts(queryString) {
            return $http.get('/api/products/search/fuzzy/' + queryString)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        return {
            getProductByUrl: getProductByUrl,
            fetchProducts: fetchProducts,
            fuzzySearchProducts: fuzzySearchProducts
        };
    }

})();
