(function () {
    'use strict';

    var fontsToLoad = ['Roboto', 'Fugaz+One', 'Just+Another+Hand', 'Alex+Brush', 'Limelight',
        'Sacramento', 'Nixie+One', 'VT323', 'Montserrat+Alternates', 'UnifrakturMaguntia', 'Tinos', 'Pacifico'
        , 'Quicksand', 'Inconsolata', 'Kumar+One', 'Barrio', 'Oswald', 'Metamorphous', 'Montserrat+Subrayada', 'Indie+Flower',
        'Audiowide', 'Baloo+Bhaina', 'Bungee+Outline']

    function isLocalStorageNameSupported() {
        var testKey = 'test', storage = window.localStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

    function insertPageNotFoundPrerenderTags() {
        var head = document.getElementsByTagName('head')[0];
        var element = document.querySelector(`meta[name='prerender-status-code']`) || null
        if (element) {
            head.removeChild(element);
        }

        var meta = document.createElement('meta');
        meta.setAttribute('name', "prerender-status-code");
        meta.setAttribute('content', '404');
        head.appendChild(meta);
    }

// dependency injection, root level
    var app = angular.module("app",
        [
            // third party
            'oc.lazyLoad',
            'ui.bootstrap',
            'ngRoute',
            'ngSanitize',
            // 'ngAnimate',
            'matchmedia-ng',

            // our own deps
            'sharedModule',
            'landingPageModule',
            'AuthService'
    ]
);


    app.config(['$routeProvider', '$locationProvider', '$ocLazyLoadProvider', 'matchmediaProvider', 'loggerProvider', function ($routeProvider, $locationProvider, $ocLazyLoadProvider, matchmediaProvider, loggerProvider) {
        $ocLazyLoadProvider.config({
            // DEBUGGING DEV ONLY - console log events
            // "debug": true,
            "events": true
        });
        // loggerProvider.setDEVMODE(true);

        // loading bar config
        matchmediaProvider.rules.phone = "(max-width: 767px)";
        matchmediaProvider.rules.tablet = "(max-width: 1023px)";
        matchmediaProvider.rules.desktop = "(min-width: 1024px)";


    // var originalWhen = $routeProvider.when;
    // $routeProvider.when = function(path, route) {
    //     route.resolve || (route.resolve = {});
    //     angular.extend(route.resolve, {
    //         'countryPromise':['CountryService', function(CountryService){
    //             return CountryService.countryPromise();
    //         }]
    //     });
    //
    //     return originalWhen.call($routeProvider, path, route);
    // };

    $routeProvider


            .when('/:locale?/thank-you-for-your-order', {
                templateUrl: "views/ThankYouForYourOrder.html"
            })

            .when('/:locale?/thank-you-for-booking', {
                templateUrl: "views/ThankYouForBooking.html",
                controller: "ThankYouForBookingController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/promotions/ThankYouForBookingCtrl.js'
                        ]);
                    }]
                }
            })

            // .when('/:locale?/our-facilities', {
            //     templateUrl: "views/our-facilities.html"
            // })

            .when('/:locale?/sports-sponsorships', {
                templateUrl: "views/sports-sponsorships.html",
                controller: "SportSponsorController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/promotions/SportSponsorCtrl.js'
                        ]);
                    }],
                }
            })

            .when('/:locale?/contract-pricing', { // contract printing removed as of December 29th 2021 by eddison.
                templateUrl: "views/Contract.html",
                controller: "ContractCtrl",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            //todo: cachedproducts
                            'shared/js/services/cachedProducts.js',
                            // 'shared/js/services/calculate.js',
                            'js/controllers/ContractCtrl.js'
                        ], {
                            serie: true
                        });
                    }]
                }
            })

            .when('/:locale?/action/:actionType/:orderNumber/:zip', {
                templateUrl: "views/UpdateActionOnOrder.html",
                controller: "ActionController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/ActionCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/thank-you-for-contacting-us', {
                templateUrl: "views/ThankYouForContactingUs.html",
                controller: "ThankYouForContactingUsController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/ThankYouForContactingUsCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/!', {
                templateUrl: 'views/home.html',
                controller: 'MainController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/MainCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/stock-colours', {
                templateUrl: 'views/SwatchColour.html',
                controller: 'SwatchController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'shared/js/services/printColoursService.js',
                            'js/controllers/one-off-pages/SwatchCtrl.js'
                        ]);
                    }]
                }
            })

        .when('/:locale?/review-request', {
            templateUrl: 'views/reviewRequest.html',
            controller: 'ReviewRequestController',
            resolve: {
                loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'js/controllers/one-off-pages/ReviewRequestCtrl.js'
                    ]);
                }]
            }
        })

            .when('/:locale?/contact', {
                templateUrl: 'views/contact.html',
                controller: 'ContactController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/ContactCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/sticker-printing', {
                templateUrl: 'views/vancouver-sticker-printing.html',
                controller: 'StickerPrintingController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/StickerPrintingCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/sign-up', {
                templateUrl: 'views/signUp.html',
                controller: 'SignUpCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/SignUpCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })


            .when('/:locale?/design-help', {
                templateUrl: "views/design_help.html",
                controller: 'DesignHelpCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/design/DesignHelpCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/login', {
                templateUrl: "views/login.html",
                controller: 'LoginCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/LoginCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/sitemap', {
                templateUrl: 'views/SiteMap.html',
                controller: 'SitemapController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // todo: cachedproducts
                            'shared/js/services/cachedProducts.js',
                            // 'shared/js/services/calculate.js',
                            'shared/js/services/location.js',
                            'js/controllers/SiteMapCtrl.js'
                        ])
                    }]
                }
            })

            .when('/:locale?/stat-holidays', {
                templateUrl: 'views/StatHoliday.html',
                controller: 'StatHolidaysCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/StatHolidayCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/products/:productCategory/:productName', {
                templateUrl: 'views/product.html',
                controller: 'ProductCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/products-nav/ProductCtrl.js',
                        ]);
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                },
                reloadOnSearch: false
            })

            .when('/:locale?/error', {
                templateUrl: 'views/404error.html'
            })

            .when('/:locale?/account', {
                templateUrl: 'views/account.html',
                controller: 'AccountCtrl',
                data: {
                    authRequired: true
                },
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/AccountCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    },
                }
            })

            .when('/:locale?/track-order', {
                templateUrl: 'views/track-order.html',
                controller: 'TrackOrderController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/TrackOrderCtrl.js'
                        ]);
                    }],
                    disableRecommendLocaleModal: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/designer/:productName', {
                templateUrl: 'views/Designer.html',
                controller: 'DesignerController',
                resolve: {
                    'fontLoaderService': ['fontLoaderService', function (fontLoaderService) {
                        return fontLoaderService.promise;
                    }],
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        loadingScreen('Loading Design Portal');
                        return $ocLazyLoad.load([
                            'js/controllers/design/DesignerCtrl.js'
                        ], {
                            serie: true
                        })
                            .finally(function () {
                                closeLoadingScreen();
                            })
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                },
                reloadOnSearch: false

            })

            .when('/:locale?/designer/:productName/:savedDesign', {
                templateUrl: 'views/Designer.html',
                controller: 'DesignerController',
                resolve: {
                    'fontLoaderService': function (fontLoaderService) {
                        return fontLoaderService.promise;
                    },
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/design/DesignerCtrl.js'
                        ], {
                            serie: true
                        });
                    }],
                },
                reloadOnSearch: false
            })


            .when('/:locale?/cart', {
                templateUrl: 'views/cart.html',
                controller: 'CartController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/CartCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }

                }
            })

            .when('/:locale?/cart/load/:oldCartId', {
                templateUrl: 'views/cart.html',
                controller: 'CartController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/CartCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/custom-products/patches/woven', {
                templateUrl: 'views/custom-woven-patches.html',
                controller: 'WovenPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/WovenPatchesCtrl.js'
                        ]);
                    }]
                }
            })


            .when('/:locale?/our-services/custom-embroidered-patches', {
                templateUrl: 'views/custom-embroidered-patches.html',
                controller: 'EmbroideredPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/EmbroideredPatchesCtrl.js'
                        ]);
                    }]
                }
            })


            .when('/:locale?/custom-products/patches/leather', {
                templateUrl: 'views/custom-leather-patches.html',
                controller: 'LeatherPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/LeatherPatchesCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/our-services/custom-sublimated-patches', {
                templateUrl: 'views/custom-sublimated-patches.html',
                controller: 'SublimatedPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/SublimatedPatchesCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/reset', {
                templateUrl: 'views/reset.html',
                controller: 'ResetCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/ResetCtrl.js'
                        ], {
                            serie: true
                        });
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }

            })

            .when('/:locale?/privacy-policy', {
                templateUrl: 'views/privacy-policy.html'
            })

            .when('/:locale?/how-to-order', {
                templateUrl: 'views/how-to-order.html',
                controller: 'HowToOrderController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/HowToOrderCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/checkout', {
                templateUrl: 'views/checkout.html',
                controller: 'CheckoutController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/CheckoutCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    },
                    forceLocaleCheck: function() {
                        return true
                    }
                }
            })

            .when('/google5684cd0b08ed36b4.html', {
                templateUrl: "views/google5684cd0b08ed36b4"
            })

            // .when('/:locale?/custom-printed-clothing-for-students', {
            //     templateUrl: "views/studentClothing.html",
            //     controller: 'studentController',
            //     resolve: {
            //         loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
            //             return $ocLazyLoad.load([
            //                 // 'shared/js/services/calculate.js',
            //                 'js/controllers/one-off-pages/promotions/StudentCtrl.js'
            //             ])
            //         }]
            //     }
            // })

            .when('/:locale?/photo-submission', {
                templateUrl: "views/photo-submission.html",
                controller: 'PhotoController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/PhotoCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/gallery', {
                templateUrl: "views/gallery.html",
                controller: 'GalleryCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/GalleryCtrl.js'
                        ]);
                    }]
                }
            })

        .when('/:locale?/custom-products/best-sellers', {
            templateUrl: "views/best-sellers.html",
            controller: 'BestSellersCtrl',
            resolve: {
                loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'libs/keen-slider/keen-slider.min.css',
                        'libs/keen-slider/keen-slider.js',
                        // 'libs/slick-carousel/slick/slick.js',
                        // 'libs/slick-carousel/slick/slick.css',
                        // 'libs/slick-carousel/slick/slick-theme.css',
                        'js/controllers/one-off-pages/BestSellersCtrl.js',
                        'js/widget/best-seller-carousel.directive.js'
                    ]);
                }]
            }
        })

            .when('/:locale?/search', {
                templateUrl: 'js/controllers/one-off-pages/global-search.html',
                controller: 'GlobalSearchCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'libs/keen-slider/keen-slider.min.css',
                            'libs/keen-slider/keen-slider.js',
                            'js/controllers/one-off-pages/GlobalSearchCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/product-search', {
                templateUrl: 'js/controllers/one-off-pages/product-search.html',
                controller: 'ProductSearchCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/ProductSearchCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/page-not-found', {
                templateUrl: "views/404error.html",
                resolve: {
                    prerenderNotFoundTag: function() {
                        insertPageNotFoundPrerenderTags();
                    }
                }
            })

             // catch the rest
            .otherwise({
                templateUrl: "js/controllers/custom-page-manager.html",
                controller: 'CustomPageManagerController',
                resolve: {
                    loadModule: ['$ocLazyLoad', '$rootScope', function ($ocLazyLoad, $rootScope) {
                        return $ocLazyLoad.load([
                            'js/controllers/customPageManager.controller.js'
                        ])
                    }]
            }
         })

        $locationProvider.html5Mode(true);
    }]);

    app.filter('unsafe', function ($sce) {
        return $sce.trustAsHtml;
    });

// attaches token with every HTTP request made to server (for forbidden routes)
    app.config(function ($httpProvider) {
        $httpProvider.interceptors.push('AuthInterceptor');
    });

    app.run(['$rootScope', '$location', '$http', '$window', "$anchorScroll", 'CommonUtilService', 'SEOService', 'PageTemplateService', '$uibModalStack', 'AnalyticsService','$sce', '$cacheFactory', 'CustomerService', 'CountryService', '$routeParams', '$timeout', 'AuthToken', '$route', function ($rootScope, $location, $http, $window, $anchorScroll, CommonUtilService, SEOService, PageTemplateService, $modalStack, AnalyticsService, $sce, $cacheFactory, CustomerService, CountryService, $routeParams, $timeout, AuthToken, $route) {
        $rootScope.initialContentLoaded = false;
        $rootScope.phoneNumber = {
            local: "604-900-5924",
            localToronto: "647-494-6266",
            tollFree: "1(888)-210-3971"
        }

        $rootScope.informationPrint = {}

        $rootScope.informationPrint["Screen Print"] = {
            image1 : "../images/examples/screen_print.jpg",
            image2 : "../images/printing_examples/screenprinting_3.jpg",
            image3 : "../images/printing_examples/screenprinting_6.jpg",
            image4 : "../images/printing_examples/screenprinting_8.jpg",
            pros : ["Vibrant colours","Cost effective on larger run orders","Durable print method","Print size does not affect pricing"],
            cons : ["More colours increase the cost of the print","Colour limitation - must be less than 8 colours","Gradients and fine details are difficult to achieve"],
            moreInfo : "/print-methods/screen-printing"
        }

        $rootScope.informationPrint["Spot Process Screen Print"] = {
            image1 : "https://coastalreign.com/images/printing_examples/spot_process_screenprint.jpg",
            // image2 : "../images/printing_examples/screenprinting_3.jpg",
            // image3 : "../images/printing_examples/screenprinting_6.jpg",
            // image4 : "../images/printing_examples/screenprinting_8.jpg",
            pros : ["Specialty colour mixing to achieve gradient and high number of colours","Cost effective on larger run orders","Durable print method","Print size does not affect pricing"],
            cons : ["More colours increase the cost of the print","Colour limitation - must be less than 8 colours","Gradients and fine details are difficult to achieve"],
            moreInfo : "/print-methods/screen-printing"
        }

        $rootScope.informationPrint["Puff Screen Print"] = {
            image1 : "https://coastalreign.com/images/printing_examples/puff_screen_printing.jpg",
            image2 : "https://images.coastalreign.com/Puff%20Screen%20Printing__1669318880514.jpg",
            // image3 : "../images/printing_examples/screenprinting_6.jpg",
            // image4 : "../images/printing_examples/screenprinting_8.jpg",
            pros : ["Specialty print method","Vibrant colours","Cost effective on larger run orders","Durable print method","Print size does not affect pricing"],
            cons : ["More colours increase the cost of the print","Colour limitation - must be less than 8 colours","Gradients and fine details are difficult to achieve"],
            moreInfo : "/print-methods/puff-screen-printing"
        }


        $rootScope.informationPrint["DTG"] = {
            image1 : "../images/examples/dtg.jpg",
            image2 : "../images/printing_examples/dtgprinting_2.jpg",
            image3 : "../images/printing_examples/dtgprinting_3.jpg",
            image4 : "../images/printing_examples/dtgprinting_4.jpg",
            pros : ["Affordable for lower quantity orders","Fine details and gradients are easily captured","Affordable for multi colour logos"],
            cons : ["Expensive for large prints","Colours look more faded than screen printing."],
            moreInfo : "/direct-to-garment"
        }

        $rootScope.informationPrint["DFT"] = {
            image1 : "https://images.coastalreign.com/DFT%20Sample%20Hung_1647624762558.jpg",
            image2 : "https://images.coastalreign.com/DFT%20Shirt_1647624598792.jpg",
            image3 : "https://images.coastalreign.com/DFT%20Tote_1655152549662.jpg",
            image4 : "https://images.coastalreign.com/DFT%20Example_1655141056953.jpg",
            pros : ["Affordable for lower quantity orders","Fine details and gradients are easily captured","Affordable for multi colour logos"],
            cons : ["Expensive for large prints","Slight sheen and rubbery feeling"],
            moreInfo : "/print-methods/direct-film-transfer"
        }

        $rootScope.informationPrint["Pad Print"] = {
            image1 : "../images/examples/padprint_example.jpeg",
            image2 : "",
            image3 : "",
            image4 : "",
            pros : ["Vibrant colours","Cost effective on larger run orders","Durable print method","Print size does not affect pricing"],
            cons : ["More colours increase the cost of the print","Colour limitation - must be less than 4 colours","Gradients and fine details can not be achieved"],
        }

        $rootScope.informationPrint["Puff Embroidery"] = {
            image1 : "https://images.coastalreign.com/puff_embroidery_1665700122512.jpeg",
            image2 : "https://images.coastalreign.com/Puff%20Embroider%20Example_1665780715273.jpg",
            image3 : "https://images.coastalreign.com/Puff%20Embroidery_1665780726018.JPG",
            image4 : "https://images.coastalreign.com/Puff%20Embroidery%20Example%202_1665780743232.jpg",
            pros : ["Professional Look","Unique Retail Finish"],
            cons : ["Expensive for smaller runs","Maximum 1 colour for puff embroidery","Fine details can not be achieved"],
            moreInfo : "/print-methods/puff-embroidery",
            description : ""
        }

        $rootScope.informationPrint["Embroidery"] = {
            image1 : "../images/examples/embroidery.jpg",
            image2 : "../images/printing_examples/embroidery_1.jpg",
            image3 : "../images/printing_examples/embroidery_2.jpg",
            image4 : "../images/printing_examples/embroidery_5.jpg",
            pros : ["Professional Look", "Durable print method"],
            cons : ["Expensive for smaller runs","Maximum of 8 colours","Fine details and gradients are hard to achieve"],
            moreInfo : "/print-methods/embroidery",
            description : ""
        }

        $rootScope.informationPrint["Embroidered Patch"] = {
            image1 : "../images/printing_examples/patch_1.jpg",
            image2 : "../images/printing_examples/patch_5.jpg",
            image3 : "../images/printing_examples/patch_11.jpg",
            image4 : "../images/printing_examples/patch_4.jpg",
            pros : ["Professional Look", "Durable print method"],
            cons : ["Expensive for smaller runs","Maximum of 8 colours","Fine details and gradients are hard to achieve"],
            moreInfo : "/our-services/custom-embroidered-patches"
        }

        $rootScope.informationPrint["Woven Label"] = {
            image1 : "https://images.coastalreign.com/Custom%20Beanie%20Labels%20Example_1674683700806.jpg",
            image2 : "https://images.coastalreign.com/Custom%20Woven%20Labels_1674770595824.JPG",
            image3 : "https://images.coastalreign.com/IMG_3453_1680801541947.JPG",
            // image4 : "../images/printing_examples/patch_4.jpg",
            pros : ["Professional Look", "Durable print method"],
            cons : ["Expensive for smaller runs","Maximum of 8 colours","Fine details and gradients are hard to achieve"],
            moreInfo : "/our-services/custom-embroidered-patches"
        }

        $rootScope.informationPrint["Woven Patch"] = {
            image1 : "https://images.coastalreign.com/Woven%20Patch%20Example%202_1681148081347.JPG",
            image2 : "https://images.coastalreign.com/Woven%20Patch%20Example%201_1681148070180.JPG",
            image3 : "https://images.coastalreign.com/Woven%20Patch%20Example%203_1681234961261.jpg",
            image4 : "https://images.coastalreign.com/Woven%20Patch%20example_1681148018042.jpg",
            pros : ["Professional Look", "Durable print method"],
            cons : ["Expensive for smaller runs","Maximum of 8 colours","Fine details and gradients are hard to achieve"],
            moreInfo : "/print-methods/woven-patches"
        }

        $rootScope.informationPrint["Leather Label"] = {
            image1 : "https://image.coastalreign.com/Custom%20Leather%20Labels_1674770477365.JPG?tr=n-image-m",
            pros : ["Unique leather finish","High quality logo"],
            cons : ["Single colour laser print impression"],
            moreInfo : "/custom-leather-patches-canada"
        }

        $rootScope.informationPrint["Leather Patch"] = {
            image1 : "../images/printing_examples/leather_patch_1.jpg",
            image2 : "../images/printing_examples/leather_patch_2.jpg",
            image3 : "../images/printing_examples/leather_patch_3.jpg",
            image4 : "../images/printing_examples/leather_patch_4.jpg",
            pros : ["Unique leather finish","High quality logo"],
            cons : ["Single colour laser print impression"],
            moreInfo : "/custom-leather-patches-canada"
        }

        $rootScope.informationPrint["Sublimated Patch"] = {
            image1 : "../images/printing_examples/patch_6.jpg",
            image2 : "../images/printing_examples/patch_2.jpg",
            image3 : "../images/printing_examples/patch_7.jpg",
            image4 : "../images/printing_examples/patch_3.jpg",
            pros : ["Fine details can be achieved","Print offers a 'no feel' touch."],
            cons : ["Colours are not as vibrant as an embroidered patch"],
            moreInfo : "/our-services/custom-sublimated-patches"
        }

        $rootScope.informationPrint["Applique"] = {
            image1 : "https://images.coastalreign.com/applique_1_1666972576192.jpeg",
            image2 : "https://images.coastalreign.com/applique_2_1666972623773.jpeg",
            image3 : "https://images.coastalreign.com/applique_3_1666972639342.jpeg",
            image4 : "https://images.coastalreign.com/applique_4_1666972646191.jpeg",
            pros : ["Varsity styled look","High quality finish and feel"],
            cons : ["High price for decoration", "Small details can not be achieved"],
            moreInfo : "/print-methods/applique-printing"
        }

        $rootScope.informationPrint["Vinyl"] = {
            image1 : "../images/examples/vinyl.jpg",
            image2 : "../images/printing_examples/vinylprinting_1.jpg",
            image3 : "../images/printing_examples/vinylprinting_3.jpg",
            image4 : "../images/printing_examples/vinylprinting_4.jpg",
            pros : ["Affordable for lower quantity orders","Affordable for multi colour logos","Fine details and gradients are easily captured"],
            cons : ["Outline is required on some designs","Other print options like screen printing are more durable","Large designs are expensive"],
            // moreInfo : "/vinyl-printing",
            description : ""
        }

        $rootScope.informationPrint["Laser"] = {
            image1 : "../images/laserprinting_examples.jpeg",
            image2 : "../images/printing_examples/laser_1.jpg",
            image3 : "../images/printing_examples/laser_2.jpg",
            image4 : "../images/printing_examples/laser_3.jpg",
            pros : ["Clean and polished look","Can print small details"],
            cons : ["Can only be a single colour imprint", "colour only limited to the colour exposed by the laser."],
            moreInfo : "",
            description : "Laser printing on mugs allows the natural colour of the mug to show through. For ceramic mugs, this is typically a creamy colour, while for metal mugs it would expose a metal look."
        }

        $rootScope.informationPrint["Laser Plus"] = $rootScope.informationPrint["Laser"]

        $rootScope.informationPrint["Deboss"] = {
            image1 : "../images/deboss_example.jpeg",
            image2 : "https://images.coastalreign.com/Deboss%20Printing%20Padfolio_1648763213383.jpg",
            image3 : "https://images.coastalreign.com/Deboss%20example_1648679036168.jpg",
            image4 : "../images/printing_examples/deboss_1.jpg",
            pros : ["Luxury look","Lasting Impression"],
            cons : ["Colour is limited to the colour of the material being pressed"],
            moreInfo : "",
            description : "By heat-pressing on the surface of the garment, deboss allows you to leave an impression of your logo or text."
        }

        $rootScope.openExampleModal = function(printType, screenPrintOptions){
            if (printType == 'Screen Print'){
                if (screenPrintOptions){
                    if (screenPrintOptions.puff){
                        printType = "Puff Screen Print"
                    } else if (screenPrintOptions.spotProcess) {
                        printType = "Spot Process Screen Print"
                    }
                }
            }
            $rootScope.printInformation = $rootScope.informationPrint[printType]
            $rootScope.printInformation.name = printType
            $('#examplesOfPrints').modal();
        }

        $rootScope.pageNotFoundPrerender = function() {
            insertPageNotFoundPrerenderTags();
            if (navigator.userAgent.indexOf('Prerender') === -1 && document.location.pathname != "/page-not-found") {
                $location.path('/page-not-found');
            }
        }

        $rootScope.goToPrintPage = function(path){
            $location.path(path);
            $('#examplesOfPrints').modal('hide');
        }

        $rootScope.informationPrint["Print"] = {
            image1 : "../images/examples/screen_print.jpg",
            image2 : "../images/printing_examples/screenprinting_3.jpg",
            image3 : "https://images.coastalreign.com/DFT%20Tote_1655152549662.jpg",
            image4 : "https://images.coastalreign.com/DFT%20Example_1655141056953.jpg",
            pros : ["Vibrant colours","Durable print method"],
            cons : ["More colours increase the cost of the print","A larger size can increase the cost"],
            description :"A print is a loose definition for screen printing or direct film transfer printing. Depending on the number of colours and size of your artwork we will automatically choose the option that is best suited for your project."
        }

        // remember images that have been loaded once
        $rootScope.lazyLoadedImagesCache = $cacheFactory('lazyLoadedImages');
        $rootScope.showSideNav = false;

        $rootScope.updateWithNewCartId = function(cartId,callback){
            $rootScope.verify(function(user){
                if (user){
                    $rootScope.updateCartWithUser(cartId, function(){
                        if (callback){
                            callback()
                        }
                    })
                } else {
                    localStorage.setItem("cartNew", cartId);
                    if (callback){
                        callback()
                    }
                }
            })
        }

        $rootScope.addItemLinesToCart = function (itemLines, products, callback) {
            AnalyticsService.trackAddItemsToCart(itemLines, products)
            $rootScope.getCartIdAndUser(function(user, cartId){
                if (cartId) {
                    // if cart is available, grab the cart and update the cart
                    $http.get('/Cart/' + cartId)
                        .success(function (response) {
                            for (var i = 0; i < itemLines.length; i++) {
                                response.listOfItems.push(itemLines[i])
                            }
                            $http.put('/Cart/' + cartId, response).success(function (updatedCart) {
                                $rootScope.updateWithNewCartId(updatedCart._id, function(){
                                    callback()
                                })
                            })
                                .error(function (response) {
                                    callback("ErrorCode:A Something went wrong when we added an item to your cart, please contact a representative and we will be happy to place your order.")
                                })
                        })
                        //if there is an invalid cart, create a new cart
                        .error(function (response) {
                            $http.post('/Cart', {listOfItems: itemLines}).success(function (response) {
                                $rootScope.updateWithNewCartId(response._id, function(){
                                    callback()
                                })
                            })
                                .error(function (response) {
                                    callback("ErrorCode:B Something went wrong when we added an item to your cart, please contact a representative and we will be happy to place your order.")
                                });
                        });
                } else { //there has not been a cart stored here before, create a new cart
                    $http.post('/Cart', {listOfItems: itemLines}).success(function (response) {
                        $rootScope.updateWithNewCartId(response._id, function(){
                            callback()
                        })
                    })
                        .error(function (response) {
                            callback("Something went wrong when we added an item to your cart, please contact a representative and we will be happy to place your order.")
                        });
                }
            })
        }

        // safari requires us to disable scroll event directly
        function disableScroll(scrollPosition) {
            document.body.style.overflow = 'hidden';
        }

        function enableScroll(scrollPosition) {
            document.body.style.removeProperty('overflow');
        }

        $rootScope.$watch('showSideNav', function () {
            $rootScope.showSideNav ? disableScroll() : enableScroll();
        })

        $rootScope.loadLiveChatScript = function () {
            LiveChatWidget.on('ready', function () {
                $rootScope.chatInitialized = true;
                $rootScope.$apply();
                LiveChatWidget.call("maximize");
            })
            LiveChatWidget.init();

            // var isLoaded = document.querySelectorAll('.ze-script');
            // if (isLoaded.length > 0) {
            //     zE('messenger', 'open');
            //     return;
            // }
            //
            // var myScript = document.createElement("script");
            // myScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=6ff0d6aa-068a-476c-8226-2a704d44ec4d';
            // myScript.id = "ze-snippet";
            // myScript.className = 'ze-script';
            // document.body.appendChild(myScript);
            // window.zdonload = setInterval(function() {
            //     if(typeof zE !== 'undefined') {
            //         clearInterval(window.zdonload);
            //         $rootScope.isChatBotLoaded = true;
            //         zE('messenger', 'open');
            //     }
            // }, 50)

        }

        $rootScope.laserPrintable = function (product) {
            return product && product.type == 'Promotional Product' && product.promoPrintMethods.includes('Laser');
        }

        $rootScope.seeIfMisMatchSupplierAndProduct = function (product, supplier) {
            return calculationService.seeIfMisMatchSupplierAndProduct(product, supplier)
        }

        $rootScope.getSupplierByName = function (name) {
            return _.find($rootScope.suppliers, function (supplier) {
                return supplier.name == name;
            })
        }

        var suppliersPromise = null

        $rootScope.getAllSuppliers = function () {
            if (!suppliersPromise) {
                suppliersPromise = $http.get('/api/suppliers/')
                    .then(function (response) {
                        $rootScope.suppliers = response.data
                        return response.data;
                    })
                    .catch(function (err) {
                        showError(err.data.message);
                        return $q.reject(err);
                    })
            }
            return suppliersPromise;
        }
        $rootScope.getAllSuppliers()


        // local : "604-327-1710",
        // tollFree : "1(800)-599-6404"

                //getcategorytext.js
        $rootScope.calculateAveragePrice = function (product, quantity) {
            var category = _.find(categoryService.allCategories, function(cat){
                return product.category == cat.name
            })
            return calculationService.calculateAveragePrice(product, quantity,category)
        }

        $rootScope.submitStoreForm = function (title, formDetails) {
            loadingScreen("Submitting Ticket ...")
            var text = ""

            _.each(formDetails, function (content, key) {
                text = text + key + " : " + content + "\r\n"
            })

            var ticket = {
                subject: title,
                from: formDetails['Email Address'],
                name: formDetails['First Name'] + " " + formDetails['Last Name'],
                text: text
            }

            $rootScope.createTicket(ticket, function (success) {
                if (success) {
                    closeLoadingScreen()
                    $location.path("/thank-you-for-contacting-us")
                } else {
                    showError("Your email failed to send.");
                    closeLoadingScreen()
                }
            })


        }

        $rootScope.setLocalStorage = function (key, value) {
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            if (isLocalStorageNameSupported()) {
                window.localStorage.setItem(key, value);
            } else {
                $rootScope[key] = value
            }
        }

        // $rootScope.getDesignPortalFromUrl = function (url, callback) {
        //     $http.post('/get-design-portal', url)
        //         .success(function (designPortal) {
        //             callback(false, designPortal)
        //         })
        //         .error(function (err) {
        //             callback(err)
        //         })
        // }

        $rootScope.getDisplayImageOfPortal = function (portal) {
            if (portal.logoIds && portal.logoIds.length > 0) {
                return portal.logoIds[0].url
            } else {
                return portal.categoryIds[0].logoIds[0].url
            }

        }

        $rootScope.getLocalStorage = function (key) {
            var item;
            if (isLocalStorageNameSupported()) {
                item = window.localStorage.getItem(key);
            } else {
                item = $rootScope[key]
            }

            if (!item) {return;}

            if (item[0] === "{") {
                try {
                    item = JSON.parse(item)
                } catch(e){

                }
            }
            return item
        }

        $rootScope.getPreviewUrl = function (url) {
            if (url.includes('res.cloudinary')) {
                return url.substr(0, url.lastIndexOf(".")) + ".png";
            } else {
                return url;
            }
        }

        // todo: async call for suppliers
        $rootScope.getLeadTimeForProduct = function (product) {
            return getLeadTimeForProduct(product, $rootScope.suppliers)
        }

        $rootScope.getSwatchProductColour = function (colour) {
            if (colour) {
                return {
                    'background-color': colour.hexVal,
                    'border-color': colour.secondaryHexVal ? colour.hexVal : 'auto',
                    'color': colour.secondaryHexVal ? colour.secondaryHexVal : 'none',
                    'border-width': colour.secondaryHexVal ? '2px' : 'none',
                    'border-style': colour.secondaryHexVal ? 'solid' : 'none',
                    'background-image': colour.secondaryHexVal ? 'linear-gradient(to left top, currentColor,currentColor 49%,transparent 50%)' : 'none'
                }
            }
        }

        $("#searchBox").on('hidden.bs.modal', function () {
            $('.modal-backdrop').css('background', '#000');
            $('.modal-backdrop').css('opacity', '0.5');
        });

        $('#searchBox').on('show.bs.modal', function (e) {
            setTimeout(function () {
                $('#searchForm').click().focus()
            }, 200);
        });

        $rootScope.isPrerender = function() {
            return navigator.userAgent.toLowerCase().includes('prerender')
        }

        $rootScope.toggleSearch = function () {
            $("#searchBox").modal('show')
            $('.modal-backdrop').css('background', 'white');
            $('.modal-backdrop').css('opacity', '0');
        }

        $rootScope.printHasAStandardColour = function (print) {
            return printColoursService.printHasStandardColour(print)
        }

        $rootScope.changeToCA = function (filter) {
            // if ($rootScope.getLocalStorage("visitedLocationPage") || filter && !$rootScope.isPrerender()) {
            //     $rootScope.setLocalStorage("visitedLocationPage", "Canada");
            // }
            // $rootScope.setLocalStorage("country", "CA");
            // $rootScope.visitingCountry = "Canada"
            CountryService.setLocale("", true);
        }

        $rootScope.getProofPreview = function (itemLine) {
            if (itemLine.proof) {
                if (itemLine.proof.includes('res.cloudinary')) {
                    var pngUrl = itemLine.proof.substr(0, itemLine.proof.lastIndexOf(".")) + ".png";
                    var indexOfUpload = pngUrl.indexOf("upload") + 7
                    pngUrl = pngUrl.slice(0, indexOfUpload) + "w_800/" + pngUrl.slice(indexOfUpload);
                    return pngUrl
                } else {
                    return itemLine.proof;
                }
            } else {
                return itemLine.mockup[0];
            }
        }

        $rootScope.onlyEmbroideryAllowed = function (category) {
            return calculationService.onlyEmbroideryAllowed(category)
        }

        // todo: refactor
        $rootScope.fromUS = function () {
            return CountryService.getCurrentLocale() === 'en-us';
            // return $rootScope.getLocalStorage("country") == "US"
        }

        $rootScope.fromCA = function () {
            return !CountryService.getCurrentLocale();
            // return $rootScope.getLocalStorage("country") == "CA";
        }

        $rootScope.getCurrentLocale = function() {
            return CountryService.getCurrentLocale();
        }

        $rootScope.localeToCountry = function(locale) {
            var returnObject = {
                countryName: '',
                flagLogo: ''
            }

            switch(locale) {
                case 'en-us':
                    returnObject = {
                    countryName: 'United States',
                    flagLogo: '/images/us-flag.png'
                    }
                    break;
                default: returnObject = {
                    countryName: 'Canada',
                    flagLogo: '/images/ca-flag.png'
                }
            }

            return returnObject;
        }

        $rootScope.visitingCountry = function(codeOnly, localeToCheck) {
            var currentLocale;
            if (!_.isUndefined(localeToCheck)) {
                currentLocale = localeToCheck
            } else {
                currentLocale = CountryService.getCurrentLocale()
            };
            switch (currentLocale) {
                case 'en-us': if (codeOnly) { return "U.S" } else {return "the United States"}
                    break
                default: if (codeOnly) {return "CA" } else { return "Canada"}
            }
        }

        //todo: refactor
        $rootScope.changeToUS = function () {
            // $rootScope.setLocalStorage("visitedLocationPage", " ");
            // $rootScope.setLocalStorage("country", "US");
            // $rootScope.visitingCountry = "the United States"
            CountryService.setLocale('en-us', true);
        }


        $rootScope.changeToCountry = function (country) {
            // set timeout prevents flicker as page reloads
            $rootScope.showRecommendLocaleModal = false;
            setTimeout(function() {
                if (country == "CA" || country == '') {
                    $rootScope.changeToCA()
                }
                if (country == "US" || country == 'en-us') {
                    $rootScope.changeToUS()
                }
            }, 0)
        }

        $rootScope.orderCurrency = function(value, currencyObject) {
            if (currencyObject) {
                return calculationService.getCurrencyValue(value, currencyObject);
            } else {
                'N/A'
            }
        }

        //current currency for any value, not tied to order
        $rootScope.currency = function(value) {
            var currency;
            switch(CountryService.getCurrentLocale()) {
                case 'en-us':
                    currency = modelService.ORDER_CURRENCY.USD;
                    break;
                case '':
                    currency = modelService.ORDER_CURRENCY.CAD
                    break;
                default:
                    currency = modelService.ORDER_CURRENCY.CAD
                    break;
            }
            var amount = calculationService.ca2us(value, currency);
            return '$' + amount.toFixed(2) + ' ' + currency;
        }

        $rootScope.sendTrackingEventForEmail = function (ticket_id) {
            $rootScope.sentTrackingEvent("email", "sent", ticket_id);
        }

        $rootScope.createTicket = function (ticketInfo, callback) {
            $http.post('/CreateTicket', ticketInfo)
                .success(function (messageId, error) {
                    callback(messageId, false)
                })
                .error(function () {
                    callback(false, true)
                })
        }

        /* Should be encoded (handles white spaces) */
        $rootScope.getTrackOrderLink = function (order) {
            if (!_.isEmpty(order)) {
                return getTrackOrderLink(order)
            } else return '';
        }

        $rootScope.getRecentTracking = function () {
            return localStorage.getItem("orderUrl")
        }

        $rootScope.getOrderStatus = function (order) {
            return getOrderStatus(order)
        }

        $rootScope.orderConfirmed = function (order) {
            var status = getOrderStatus(order)
            return status == "Printing" || status == "Shipped"
        }

        $rootScope.getLastStatusDate = function (order) {
            return getLastStatusDate(order)
        }


        $rootScope.encodeURI = function (str) {
            return encodeURI(str).toLowerCase()
        }

        $rootScope.decodeURI = function (str) {
            return decodeURI(str).toLowerCase()
        }

        $rootScope.breadcrumblist = [];
        $rootScope.recommendLocale;

        // set min viewheight to prevent flickering as custom-page-manager loads
        //https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        $rootScope.setVh = function() {
            var vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        $rootScope.$on('$routeChangeStart', function (event, next, current) {
            $modalStack.dismissAll();
            $('.modal-backdrop').remove();
            $('body').removeClass("modal-open");
            var routeLocale = next.params.locale;
            // todo: hack locale param not piped to custompagemanager, need to peek into url
            if (!next.$$route) {
                var firstSubdirectory = $location.path().split("/")[1]
                if (CountryService.isValidLocale(firstSubdirectory)) {
                    routeLocale = firstSubdirectory;
                }
                setTimeout(function() {
                    $("#footer-container").css("display", 'none');
                });
            }

            if (!routeLocale) {
                routeLocale = ""; // default to en-ca
            }

            // on initial app load only
            var disableRecommendLocaleModal = false;
            var forceLocaleCheck = false;
            try {
                if (next.resolve.disableRecommendLocaleModal) {
                    disableRecommendLocaleModal = next.resolve.disableRecommendLocaleModal()
                }
                if (next.resolve.forceLocaleCheck) {
                    forceLocaleCheck = next.resolve.forceLocaleCheck();
                }
            } catch (e) {

            }
            if (!current) {
                try {
                    $rootScope.setVh();
                    CountryService.setLocale(routeLocale);
                    CountryService.validatePageLocale(forceLocaleCheck, disableRecommendLocaleModal);
                } catch (e) {
                    console.log(e);
                    $location.path('/page-not-found')
                }
            } else {
                // internal navigation
                // url changes via $location.path() needs to respect locale
                var currentLocale = CountryService.getCurrentLocale();
                if (next !== current && currentLocale) {
                    if (routeLocale !== currentLocale) {
                        event.preventDefault();
                        // need to cancel url change and update  w.r.t locale
                        CountryService.reloadUrl();
                    }
                }

                if (forceLocaleCheck) {
                    CountryService.validatePageLocale(forceLocaleCheck, disableRecommendLocaleModal);
                }
            }

            if (next.data && next.data.authRequired) {
                if (!AuthToken.isLoggedIn()){
                    event.preventDefault();
                    $location.path('/login');
                }
            }
        });

        // clicked close on the modal
        $rootScope.userConfirmLocale = function() {
            $rootScope.showRecommendLocaleModal = false
            $rootScope.setLocalStorage('userConfirmedLocation', Date.now() + (60000 * 60 * 24 * 5));
        }

        // initial page load
        $rootScope.$on('$viewContentLoaded', function () {
            // page manager handles it in controller
            if ($route.current.loadedTemplateUrl !== "js/controllers/custom-page-manager.html") {
                // page loaded gtag event
                if (window.dataLayer && !$rootScope.initialViewContentLoaded) {
                    setTimeout(function(){
                        window.dataLayer.push({
                            'event': 'initial_view_content_loaded'
                        });
                    }, 3500)
                 $rootScope.initialViewContentLoaded = true;
                }
                setTimeout(function(){
                    $("#footer-container").css("display", 'block');
                }, 250)
                //Here your view content is fully loaded !!
            }
        });

        // todo: remove Google UA event tracking eventually
        $rootScope.sentTrackingEvent = function (category, action, label, value) {
            if (window.ga) {
                window.ga('send', 'event', category, action, label, value)
            }
        }

        if (window.ga) {
            window.ga('create', 'UA-59840738-2', 'auto');
        }

        $rootScope.$on('$routeChangeSuccess', function (event, next, current) {
            // on route change scroll to top of page
            $anchorScroll();
            var navMain = $("#bs-example-navbar-collapse-1");
            navMain.collapse('hide');
            if (window.ga) {
                window.ga('send', 'pageview', $location.path());
            }

            // remove locale
            function getCurrentBasePath() {
                var urlPath = $location.path().split('/');
                urlPath.shift();
                if (CountryService.isValidLocale(urlPath[0])) {
                    urlPath.shift();
                }
                urlPath = urlPath.join('/');
                return '/' + urlPath;
            }

            if (!next.$$route || !next.locals.calculateBreadcrumbs) {
                PageTemplateService.getBreadcrumb(getCurrentBasePath().substring(1))
                    .then(function (res) {
                        // match async call with current route
                        if (res.url === getCurrentBasePath().substring(1)) {
                            $rootScope.breadcrumblist = res.breadcrumb;
                        } else {
                            $rootScope.breadcrumblist = [];
                        }
                    })
                    .catch(function (err) {
                        $rootScope.breadcrumblist = [];
                    })
            }


            // update canonical element
            var host = $location.protocol() + '://' + location.host;
            // no query params
            var fullUrl = host + $location.path()
            SEOService.updateCanonicalTag(fullUrl);

            var basePath;
            if (!next.locals.disableHrefLang) {
                basePath = getCurrentBasePath();
            }
            SEOService.updateHrefLangTags(host, basePath);
        });

        $rootScope.smoothScrollTo = function (target) {
            var $target = $(target);
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 750, 'swing', function () {

            });
        }

        $rootScope.signUp = function (user, callback) {
            $http.post('/User/', user)
                .success(function (userToken) {
                    AnalyticsService.customTrackSignUp(user.username)
                    callback(userToken)
                })
                .error(function () {
                    callback()
                })
        }

        // determine best guess breadcrumb from product metadata
        $rootScope.getBreadcrumbFromProduct = function(product) {
            // 1. test to see if it is a special page
            //TODO:
            var foundCategory = _.find(categoryService.comboCategories, function(comboCat) {

            })

            // 2. fall back to product category page
            //getcategorytext.js
            foundCategory = _.find(categoryService.allCategories, function(cat) {
                return cat.name === product.category
            });

            var breadcrumbUrl = '/custom-products';
            if (foundCategory &&  foundCategory.categoryUrl) {
                breadcrumbUrl = foundCategory.categoryUrl
            }
            breadcrumbUrl = breadcrumbUrl.substring(1);
            var productUrlWithLocale = CommonUtilService.buildProductUrl(product);
            var currentLocale = CountryService.getCurrentLocale();
            if (currentLocale) {
                productUrlWithLocale = '/' + currentLocale + productUrlWithLocale;
            }
                return PageTemplateService.getBreadcrumb(breadcrumbUrl)
                .then(function(breadcrumbRes) {
                    if (breadcrumbRes.url === breadcrumbUrl) {
                        breadcrumbRes.breadcrumb.unshift({
                            targetKeyword: CommonUtilService.toTitleCase(product.name),
                            // need current url path to handle locale
                            pageUrl: productUrlWithLocale.substring(1)
                        })
                        $rootScope.breadcrumblist = breadcrumbRes.breadcrumb
                        return breadcrumbRes.breadcrumb;
                    }
                })
        }

        //getcategorytext.js
        $rootScope.findCategoryFromString = function(categoryString){
            function replaceHyphenWithStrings(inputText, replacementString) {
                var replacedText = inputText.replace(/-/g, replacementString);
                return replacedText;
            }

            var category = _.find(categoryService.allCategories, function(category){
                var matching = replaceHyphenWithStrings(categoryString," ").toLowerCase()
                return matching == replaceHyphenWithStrings(category.name, " ").toLowerCase()
            })

            if (!category){
                category = categoryService.defaultCategory;
            }
            return category
        }


        $rootScope.verify = function (callback) {
            if (window.localStorage.userToken){
                $http.post('/Verify', {token: window.localStorage.userToken})
                    .success(function (user) {
                        callback(user)
                    })

                    .error(function () {
                        callback(false)
                    })

            } else {
                callback(false)
            }
        }


        $rootScope.addLocaleToJotForm = function(jotformurl){
            if($rootScope.fromUS()){
                jotformurl = jotformurl + "?local=en-us/"
            }
            return jotformurl
        }


        $rootScope.getDesignHelpForm = function(){
            return $rootScope.addLocaleToJotForm("https://form.jotform.com/231234543785256")
        }

        $rootScope.compressImage = function (url) {
            return CommonUtilService.compressImage(url);
        }

        $rootScope.getJotFormUrlFromProduct = function(product, productColour){
            var url = "https://form.jotform.com/230964611728258?w=" + encodeURI(product.name)

             if (productColour){
                 url = url + "&whatColour=" + encodeURI(productColour)
             }
            return $sce.trustAsResourceUrl(url)
        }

        $rootScope.getProductImage = function(product,colourIndex,view){
            if (product && product.colours[colourIndex]&& product.colours[colourIndex][view]) {
                return product.colours[colourIndex][view];
            } else {
                return false
            }
        }

        $rootScope.getProductFront = function (product, index) {
            if (product && product.colours[index].front) {
                return product.colours[index].front;
            }
        }

        $rootScope.getProductBack = function (product, index) {
            if (product && product.colours[index].back) {
                return product.colours[index].back;
            }
        }

        $rootScope.getPromoDecorationGroupsForProduct = function (product) {
            var printTypes = [];
            _.forOwn(calculationService.promoProductDecorationGrouping, function (grouping, key) {
                if (_.some(grouping.list, function (printName) {
                    return product.promoPrintMethods.includes(printName)
                })) {
                    printTypes.push(grouping.name)
                }
            });
            return printTypes;
        }


        $rootScope.codeLogin = function (username, oneTimeCode, callback) {
            var request = {
                username: username,
                oneTimeCode: oneTimeCode
            }

            $http.post('/AuthenticateUserWithCode', request)
                .success(function (response) {
                    window.localStorage.userToken = response.token;
                    callback("true")
                })
                .error(function (response) {
                    callback(false)
                });
        }

        $rootScope.deleteUpload = function(upload, accountUploads){
            $http.post('/DeleteUpload', {token: window.localStorage.userToken, uploadId : upload._id})
                .success(function () {
                    var uploadIndex = _.findIndex(accountUploads, function(existingUpload){
                        return existingUpload._id == upload._id
                    })
                    accountUploads.splice(uploadIndex,1)
                })
                .error(function () {
                    showError("Could not delete design, please try again later.")
                })
        }

        $rootScope.truncateText = function(str, length){
            if (str.length > length) {
                return str.substring(0, length - 3) + '...';
            } else {
                return str;
            }
        }

        $rootScope.getUserToken = function(){
            return window.localStorage.userToken
        }

        $rootScope.setToken = function(token){
            window.localStorage.userToken = token;
        }


        $rootScope.login = function (username, password, callback) {
            var request = {
                username: username,
                password: password
            }

            $http.post('/AuthenticateUser', request)
                .success(function (response) {
                    $rootScope.setToken(response.token)
                    callback("true")
                })

                .error(function (response) {
                    callback(false)
                });
        }

        $rootScope.getProductUrl = function (product) {
            return CommonUtilService.buildProductUrl(product)
        }


        $rootScope.goToProduct = function (product) {
            $location.path(CommonUtilService.buildProductUrl(product)).search({});
        }

        $rootScope.getCartTotal = function (cart) {
            var cartTotal = 0;
            if (cart) {
                for (var i = 0; i < cart.length; i++) {
                    cartTotal = (cartTotal + cart[i].price * cart[i].totalQty);
                }
            }
            return cartTotal

        }

        $rootScope.updateCartWithUser = function(cartId,callback){
            CustomerService.updateCart(cartId)
                .then(function() {
                    if(callback){
                        callback()
                    }
                });
        }



        $rootScope.getCartIdAndUser = function(callback){
            $rootScope.verify(function(user) {
                var localCart = $rootScope.getLocalCartId();
                if (user){
                    if (user.currentCart){
                        callback(user,user.currentCart)
                    } else if (localCart){
                        $rootScope.updateCartWithUser(localCart, function(){
                            localStorage.setItem("cartNew", "")
                            callback(user,localCart)
                        })
                    } else {
                        callback(user,localCart)
                    }
                } else {
                    callback(false,localCart)
                }
            })
        }

        $rootScope.getLocalCartId = function () {
            var cartId = localStorage.getItem("cartNew");
            return cartId
        }

        $rootScope.refreshUserAndCartId = function(){
            $rootScope.getCartIdAndUser(function(user,cartId){
                $rootScope.user = user
                $rootScope.cartId = cartId
            })
        }

        $rootScope.refreshUserAndCartId()



        $rootScope.updateCartInDb = function (cart, callback) {
            $http.put('/Cart/' + cart._id, cart)
                .success(function (response) {
                    callback(true)
                })
                .error(function () {
                    callback(false)
                })
        }


        $rootScope.getCart = function (cartId, callback) {
            if (cartId) {
                $http.get('/Cart/' + cartId).success(function (response) {
                    if (!response) {
                        callback({listOfItems: []})
                    } else {
                        callback(response)
                    }

                })
                    .error(function () {
                        callback({listOfItems: []})
                    });
            } else {
                callback({listOfItems: []})
            }
        }

        $rootScope.fontsToLoad = fontsToLoad
    }]);

    app.service('fontLoaderService', ['$q', function ($q) {
        var deferred = $q.defer();

        WebFont.load({
            google: {
                families: fontsToLoad
            },
            custom: {
                families: [
                    'Athletic'
                ],
                urls: [
                    '../fonts/fonts.css'
                ]
            },
            active: function () {
                deferred.resolve();
            }
        });

        return {
            promise: deferred
        };
    }])

    // refresh all anchor elements with locale and relative domain
    app.directive('a', ['CountryService', function(CountryService) {
        return {
            restrict: 'E',
            link: function($scope, element, attrs) {

                $scope.localeSet = CountryService.getCurrentLocale();
                function updateHref() {
                    if (attrs.href !== '#' && attrs.href && !attrs.localeSet && !_.isUndefined(CountryService.getCurrentLocale())) {
                        // pass in absolute url from anchor context
                        attrs.$set('localeSet', true); // break loop
                        attrs.$set('href', CountryService.getRelativeLocaleUrl(element.context.href));
                    }
                }

                attrs.$observe('href', function(){
                    updateHref();
                });

                $scope.$on('localeReady', function() {
                    updateHref();
                })
            }
        }
    }]);

    app.directive('loginForm', function () {
        return {
            restrict: 'E',
            scope: {
                onLoginSuccess: '&',
                email: '=',
                signUp : '='
            },
            templateUrl: '../../views/login-screen-directive.html',
            controller: ['$scope', '$http', '$location', '$rootScope', function ($scope, $http, $location, $rootScope) {

                $scope.$watch('email', function(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        $scope.user.username = newVal || '';
                    }
                });

                $scope.user = {
                    username : $scope.email
                }
                $scope.login = function () {
                    $rootScope.login($scope.user.username, $scope.user.password, function (success) {
                        if (success) {
                            $rootScope.refreshUserAndCartId();
                            $scope.onLoginSuccess();
                        } else {
                            showError("Username and password combination is incorrect");
                        }
                    });
                };

                $scope.codeLoginForm = function () {
                    loadingScreen("Verifying Code");
                    $rootScope.codeLogin($scope.user.username, $scope.oneTimeCode, function (success) {
                        if (success) {
                            closeLoadingScreen();
                            $rootScope.refreshUserAndCartId();
                            $scope.onLoginSuccess();
                        } else {
                            closeLoadingScreen();
                            showError("Code Incorrect");
                        }
                    });
                };

                $scope.forgotPassword = function () {
                    $scope.forgotPasswordScreen = true;
                    $scope.loginPassword = false;
                };

                $scope.loginWithPassword = function () {
                    $scope.oneTimeCode = "";
                    $scope.loginPassword = true;
                    $scope.loginCode = false;
                    $scope.forgotPasswordScreen = false;
                };

                $scope.sendOneTimeCode = function () {
                    var request = {
                        username: $scope.user.username
                    };

                    loadingScreen("Sending Code");
                    $http.post('/SendOneTimeCode', request)
                        .success(function () {
                            closeLoadingScreen();
                            $scope.oneTimeCodeSent = true;
                        })
                        .error(function (token) {
                            closeLoadingScreen();
                            showError("Sorry, the email you entered is not valid.");
                        });
                };

                $scope.loginWithOneTimeCode = function () {
                    $scope.loginPassword = false;
                    $scope.loginCode = true;
                    $scope.oneTimeCode = "";
                    $scope.user.username = "";
                    $scope.oneTimeCodeSent = false;
                };

                $scope.loginWithPassword();

                $scope.resetPassword = function () {
                    if (!$scope.user.username) {
                        showError("Please enter your email and then proceed to clicking \"Forgot Password\"");
                        return;
                    }
                    loadingScreen("Resetting...");
                    var request = {
                        username: $scope.user.username
                    };

                    $http.post('/ForgotPassword', request)
                        .success(function (token) {
                            closeLoadingScreen();
                            success("If your email is in our database, a link will be sent to reset your password.");
                            $scope.forgotPasswordScreen = false;
                            $scope.loginPassword = true;
                        })
                        .error(function (token) {
                            closeLoadingScreen();
                            success("If your email is in our database, a link will be sent to reset your password.");
                            $scope.forgotPasswordScreen = false;
                            $scope.loginPassword = true;
                        });
                };
            }]
        };
    });

    app.directive('onErrorSrc', function() {
        return {
            link: function(scope, element, attrs) {
                element.bind('error', function() {
                    if (attrs.src != attrs.onErrorSrc) {
                        attrs.$set('src', attrs.onErrorSrc);
                    }
                });
            }
        }})

    app.directive('fabric', function () {
        return {
            templateUrl: '../../views/fabric-directive.html'
        };
    });

    app.directive('cantEmpty', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                function isEmpty(value) {
                    return !value || !value.trim();
                }

                function setInvalid() {
                    if (element.is('input[type="radio"]')) {
                        element.parent().parent().css({
                            'border': '2px solid #a94442',
                            'border-radius': '10px',
                            'padding' : '2px'
                        });
                    } else {
                        element.css({
                            'border': '2px solid #a94442',
                            'border-radius': '10px',
                            'padding' : '2px'
                        });
                    }

                    // Scroll to the element
                    element[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                function setValid() {
                    if (element.is('input[type="radio"]')) {
                        element.parent().parent().css({
                            'border': '',
                            'border-radius': '0px',
                            'padding' : '0px'

                        });
                    } else {
                        element.css({
                            'border': '',
                            'border-radius': '0px',
                            'padding' : '0px'
                        });
                    }
                }


                function validate() {
                    ngModelCtrl.$setValidity('cantEmpty', !isEmpty(ngModelCtrl.$viewValue));
                    if (!ngModelCtrl.$valid) {
                        setInvalid();
                    } else {
                        setValid();
                    }
                }

                // Validate on form submission
                scope.$on('formSubmitted', function() {
                    validate();
                });

                // Validate on input change
                element.on('input', function() {
                    scope.$apply(function() {
                        validate();
                    });
                });

                element.on('change', function() {
                    scope.$apply(function() {
                        validate();
                    });
                });
            }
        };
    });

    app.directive('valuesCheckBox', ['CommonUtilService',  function (CommonUtilService) {
        return {
            templateUrl: '../../views/values-checkbox-directive.html',
            scope: {
                prefix: '@prefix',
                titleheader: '@titleheader',
                aprefix: '@aprefix',
                postorder: '@postorder'
            },
            link: function ($scope) {

            }
        };
    }]);

// remove this because we have location-section
//     app.directive('locations', ['CommonUtilService',  'CountryService', function (CommonUtilService, CountryService) {
//         return {
//             templateUrl: '../../views/location-page-links.html',
//             scope: {
//                 prefix: '@prefix',
//                 titleheader: '@titleheader',
//                 aprefix: '@aprefix'
//             },
//             link: function ($scope) {
//
//                 function chunk(arr, size) {
//                     var newArr = [];
//                     for (var i = 0; i < arr.length; i += size) {
//                         newArr.push(arr.slice(i, i + size));
//                     }
//                     return newArr;
//                 }
//
//                 $scope.getLink = function (location) {
//                     return CommonUtilService.buildUrl(location)
//                 }
//
//                 $scope.chunkedData = chunk(getLocations(), 3);
//             }
//         };
//     }]);


    app.directive('clients', function () {
        return {
            templateUrl: 'views/clients.html',
            link: function () {
            }
        }
    });

    app.directive("ngMobileClick", [function () {
        return function (scope, elem, attrs) {
            elem.bind("touchstart click", function (e) {
                e.preventDefault();
                e.stopPropagation();

                scope.$apply(attrs["ngMobileClick"]);
            });
        }
    }])


    app.directive('designportalschool', function () {
        return {
            restrict: 'E',
            scope: {
                school: '=school'
            },
            templateUrl: '../../views/design-portal-schools.html',
            link: function ($scope, $elem, $attr, $routeParams) {
                var transparent = "rgba(0, 0, 0, 0)"
                var width = $scope.school.width
                $scope.getMeasurementByInterval = function (section) {
                    return section / 16 * width
                }


                $scope.school.schoolName = $scope.school.schoolName.toUpperCase()
                $scope.school.schoolName = $scope.school.schoolName.replace("SECONDARY", "")
                $scope.school.schoolName = $scope.school.schoolName.replace("ELEMENTARY", "")
                $scope.school.schoolName = $scope.school.schoolName.replace("&", "")


                $scope.school.schoolName = $scope.school.schoolName.replace("SCHOOL", "")
                $scope.school.schoolName = $scope.school.schoolName.trim()

                function colourIsInvalid(hex) {
                    return (hex.toUpperCase() == "#FFFFFFF" || hex.length < 4)
                }

                if (colourIsInvalid($scope.school.mainColour)) {
                    $scope.school.mainColour = "#000000"
                }

                if (colourIsInvalid($scope.school.secondaryColour)) {
                    $scope.school.secondaryColour = "#000000"
                }

                if ($scope.school.mascotName.length < 4) {
                    $scope.school.mascotName = $scope.school.schoolName
                } else {
                    $scope.school.mascotName = $scope.school.mascotName.toUpperCase()
                }


                $scope.createAndAddCanvas = function (canvasId, category) {
                    var canvasId = category.concat(canvasId)
                    var canvasToAdd = new fabric.Canvas(canvasId, {
                        selectionColor: 'rgba(0,0,0,0)',
                        selectionBorderColor: 'rgba(0,0,0,0)',
                        selectionLineWidth: 2,
                        height: width,
                        width: width
                    });
                    return canvasToAdd
                }

                // {
                //     link : $scope.school.logoUrl,
                //         left : $scope.getMeasurementByInterval(8),
                //     top : $scope.getMeasurementByInterval(8),
                //     scale : $scope.getMeasurementByInterval(3),
                //     stroke : transparent
                // },
                // {
                //     text :$scope.school.schoolName,
                //         left : $scope.getMeasurementByInterval(8),
                //     fontFamily : "Athletic",
                //     top: $scope.getMeasurementByInterval(2),
                //     fill : $scope.school.mainColour,
                //     textAlign : "center",
                //     fontSize : $scope.getMeasurementByInterval(4),
                //     width :$scope.getMeasurementByInterval(16)
                // }

                function createSportsOneDesign(sportName) {
                    return {
                        designName: "Sports_1" + sportName,
                        objects: [
                            {
                                text: $scope.school.mascotName,
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Athletic",
                                top: $scope.getMeasurementByInterval(2),
                                charSpacing: $scope.getMeasurementByInterval(0),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: transparent,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                link: $scope.school.logoUrl,
                                left: $scope.getMeasurementByInterval(8),
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(5),
                                stroke: transparent,
                                strokeWidth: $scope.getMeasurementByInterval(0)
                            },
                            {
                                text: sportName,
                                left: $scope.getMeasurementByInterval(8),
                                charSpacing: $scope.getMeasurementByInterval(0),

                                fontFamily: "Athletic",
                                top: $scope.getMeasurementByInterval(12),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: transparent,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            }
                        ]
                    }
                }


                function createClubSevenDesign(sportName) {
                    return {
                        designName: "Club_7" + sportName,
                        objects: [
                            {
                                text: $scope.school.schoolName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Quicksand",
                                top: $scope.getMeasurementByInterval(5),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Quicksand",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(16)
                            }

                        ]
                    }
                }


                function createSportsTwoDesign(sportName) {
                    return {
                        designName: "Sports_2" + sportName,
                        objects: [
                            {
                                text: $scope.school.mascotName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Athletic",
                                top: $scope.getMeasurementByInterval(2),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Athletic",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(5),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                link: $scope.school.logoUrl,
                                left: $scope.getMeasurementByInterval(8),
                                top: $scope.getMeasurementByInterval(9),
                                scale: $scope.getMeasurementByInterval(5)
                            }

                        ]
                    }
                }

                function createSportsThreeDesign(sportName, icon) {
                    sportName = sportName.toUpperCase()
                    return {
                        designName: "Sports_3" + sportName,
                        objects: [
                            {
                                text: $scope.school.mascotName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(3),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(11.5),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                link: "line",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(16)
                            },
                            {
                                link: "circle",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(3)
                            },
                            {
                                link: icon,
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(2)
                            }

                        ]
                    }
                }

                function createSportsFourDesign(sportName, icon, noFill) {
                    sportName = sportName.toUpperCase()

                    var iconObject
                    if (noFill) {
                        iconObject = {
                            link: icon,
                            left: $scope.getMeasurementByInterval(8),
                            top: $scope.getMeasurementByInterval(4),
                            scale: $scope.getMeasurementByInterval(6)
                        }
                    } else {
                        iconObject = {
                            link: icon,
                            left: $scope.getMeasurementByInterval(8),
                            fill: $scope.school.secondaryColour,
                            top: $scope.getMeasurementByInterval(4),
                            scale: $scope.getMeasurementByInterval(6)
                        }
                    }
                    return {
                        designName: "Sports_4" + sportName,


                        objects: [
                            iconObject,
                            {
                                text: $scope.school.mascotName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Fugaz One",
                                top: $scope.getMeasurementByInterval(9),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Fugaz One",
                                top: $scope.getMeasurementByInterval(12.5),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(14)
                            }


                        ]
                    }
                }


                function createSportsFiveDesign(sportName) {
                    sportName = sportName.toUpperCase()
                    return {
                        designName: "Sports_5" + sportName,
                        objects: [
                            {
                                link: $scope.school.logoUrl,
                                left: $scope.getMeasurementByInterval(8),
                                top: $scope.getMeasurementByInterval(8),
                                scale: $scope.getMeasurementByInterval(6)
                            },
                            {
                                text: $scope.school.mascotName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Fugaz One",
                                top: $scope.getMeasurementByInterval(2),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Fugaz One",
                                top: $scope.getMeasurementByInterval(14),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(14)
                            }
                        ]
                    }
                }

                function createSportsSixDesign(sportName) {
                    return {
                        designName: "Sports_6" + sportName,
                        objects: [
                            {
                                text: $scope.school.mascotName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Fugaz One",
                                top: $scope.getMeasurementByInterval(2),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Fugaz One",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(5),
                                fill: $scope.school.secondaryColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                link: $scope.school.logoUrl,
                                left: $scope.getMeasurementByInterval(8),
                                top: $scope.getMeasurementByInterval(9),
                                scale: $scope.getMeasurementByInterval(5)
                            }
                        ]
                    }
                }

                function createClubTwoDesign(categoryName, icon1) {
                    return {
                        designName: "Club_Two" + categoryName,
                        objects: [
                            {
                                link: "circle_empty",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(8),
                                scale: $scope.getMeasurementByInterval(11)
                            },
                            // {
                            //     link : "../images/schooldesigns/outline_circle.svg",
                            //     left : $scope.getMeasurementByInterval(8),
                            //     fill : $scope.school.secondaryColour,
                            //     top : $scope.getMeasurementByInterval(6),
                            //     scale : $scope.getMeasurementByInterval(12)
                            // },
                            {
                                link: icon1,
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(8),
                                scale: $scope.getMeasurementByInterval(4)
                            },
                            {
                                text: categoryName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(15),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(10)
                            }
                            ,
                            {
                                text: $scope.school.schoolName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(1.25),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(10)
                            }
                        ]
                    }
                }


                function createClubSixDesign(catName) {
                    catName = catName.toUpperCase()
                    return {
                        designName: "Club_6" + catName,
                        objects: [
                            {
                                link: $scope.school.logoUrl,
                                left: $scope.getMeasurementByInterval(8),
                                top: $scope.getMeasurementByInterval(4),
                                scale: $scope.getMeasurementByInterval(6)
                            },
                            {
                                text: catName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Quicksand",
                                top: $scope.getMeasurementByInterval(9),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            }

                        ]
                    }
                }

                function createClubFourDesign(catName, icon) {
                    catName = catName.toUpperCase()
                    return {
                        designName: "Club_4" + catName,
                        objects: [
                            {
                                link: icon,
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(4),
                                scale: $scope.getMeasurementByInterval(6)
                            },
                            {
                                text: catName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Quicksand",
                                top: $scope.getMeasurementByInterval(9),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            }

                        ]
                    }
                }

                function createClubThreeDesign(catName, icon) {
                    catName = catName.toUpperCase()
                    return {
                        designName: "Club_3" + catName,
                        objects: [
                            {
                                link: icon,
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(4),
                                scale: $scope.getMeasurementByInterval(6)
                            },
                            {
                                text: $scope.school.schoolName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(9),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(16)
                            },
                            {
                                text: catName,
                                charSpacing: $scope.getMeasurementByInterval(16),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(12.5),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(14)
                            }


                        ]
                    }
                }

                function createGradDesignOne() {
                    return {
                        designName: "Grad_One",
                        objects: [
                            {
                                link: "gradone",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(14)
                            }
                        ]
                    }
                }

                function createGradDesignThree() {
                    return {
                        designName: "Grad_Three",
                        objects: [
                            {
                                link: "gradthree_one",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(14)
                            },
                            {
                                link: "gradthree_two",
                                left: $scope.getMeasurementByInterval(8.1),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(8.2),
                                scale: $scope.getMeasurementByInterval(14)
                            }
                        ]
                    }
                }

                function createGradDesignFour() {
                    return {
                        designName: "Grad_Four",
                        objects: [
                            {
                                link: "gradfour",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(14)
                            }
                        ]
                    }
                }

                function createGradDesignEight() {
                    return {
                        designName: "Grad_Eight",
                        objects: [
                            {
                                link: "gradeight_one",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(12)
                            },
                            {
                                link: "gradeight_two",
                                left: $scope.getMeasurementByInterval(8.3),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(8.55),
                                scale: $scope.getMeasurementByInterval(13.4)
                            },
                            {
                                link: "gradeight_three",
                                left: $scope.getMeasurementByInterval(8.8),
                                fill: "#000000",
                                top: $scope.getMeasurementByInterval(7.75),
                                scale: $scope.getMeasurementByInterval(12.3)
                            }
                        ]
                    }
                }

                function createGradDesignTen() {
                    return {
                        designName: "Grad_Ten",
                        objects: [
                            {
                                link: "gradten_three",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(10.95)
                            }
                            ,

                            {
                                link: "gradten_one",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(8.5),
                                scale: $scope.getMeasurementByInterval(12)
                            },
                            {
                                link: "gradten_two",
                                left: $scope.getMeasurementByInterval(8),
                                fill: "#ffffff",
                                top: $scope.getMeasurementByInterval(8.5),
                                scale: $scope.getMeasurementByInterval(10.95)
                            }
                        ]
                    }
                }


                function createGradDesignNine() {
                    return {
                        designName: "Grad_Nine",
                        objects: [
                            {
                                link: "gradnine_one",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(6),
                                scale: $scope.getMeasurementByInterval(12)
                            },
                            {
                                link: "gradnine_two",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(5),
                                scale: $scope.getMeasurementByInterval(10.95)
                            }
                        ]
                    }
                }


                function createGradDesignSeven() {
                    return {
                        designName: "Grad_Seven",
                        objects: [
                            {
                                link: "gradseven_one",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(6),
                                scale: $scope.getMeasurementByInterval(12)
                            },
                            {
                                link: "gradseven_two",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(9.25),
                                scale: $scope.getMeasurementByInterval(12)
                            }
                        ]
                    }
                }


                function createGradDesignSix() {
                    return {
                        designName: "Grad_Six",
                        objects: [
                            {
                                link: "gradsix_one",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(10)
                            },
                            {
                                link: "gradsix_two",
                                left: $scope.getMeasurementByInterval(8.37),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(8.1),
                                scale: $scope.getMeasurementByInterval(11.1)
                            }
                        ]
                    }
                }

                function createGradDesignFive() {
                    return {
                        designName: "Grad_Five",
                        objects: [
                            {
                                link: "gradfive",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.mainColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(14)
                            }
                        ]
                    }
                }


                function createGradDesignTwo() {
                    return {
                        designName: "Grad_Two",
                        objects: [
                            {
                                text: $scope.school.schoolName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(8),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(3),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(2),
                                width: $scope.getMeasurementByInterval(10)
                            },
                            {
                                link: "gradtwo",
                                left: $scope.getMeasurementByInterval(8),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(7),
                                scale: $scope.getMeasurementByInterval(14)
                            }
                        ]
                    }
                }

                function createClubOneDesign(categoryName, icon1) {
                    return {
                        designName: "Club_One" + categoryName,
                        objects: [
                            {
                                text: $scope.school.schoolName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(11),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(4),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(10)
                            },
                            {
                                text: categoryName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(11),
                                fontFamily: "Oswald",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(7),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(10)
                            },
                            {
                                link: icon1,
                                left: $scope.getMeasurementByInterval(3),
                                fill: $scope.school.secondaryColour,
                                top: $scope.getMeasurementByInterval(5),
                                scale: $scope.getMeasurementByInterval(4)
                            }
                        ]
                    }
                }

                function createClubFiveDesign(categoryName, icon1) {
                    return {
                        designName: "Club_Five" + categoryName,
                        objects: [
                            {
                                text: $scope.school.schoolName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(11),
                                fontFamily: "Oswald",
                                top: $scope.getMeasurementByInterval(4),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(10)
                            },
                            {
                                text: categoryName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(11),
                                fontFamily: "Oswald",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(7),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(10)
                            },
                            {
                                link: $scope.school.logoUrl,
                                left: $scope.getMeasurementByInterval(3),
                                top: $scope.getMeasurementByInterval(5),
                                scale: $scope.getMeasurementByInterval(4)
                            }
                        ]
                    }
                }

                function createSportsSevenDesign(sportName, icon, noFill) {
                    var objectFill
                    if (noFill) {
                        objectFill = {
                            link: icon,
                            left: $scope.getMeasurementByInterval(3),
                            top: $scope.getMeasurementByInterval(5),
                            scale: $scope.getMeasurementByInterval(5)
                        }
                    } else {
                        objectFill = {
                            link: icon,
                            left: $scope.getMeasurementByInterval(3),
                            fill: $scope.school.secondaryColour,
                            top: $scope.getMeasurementByInterval(5),
                            scale: $scope.getMeasurementByInterval(5)
                        }
                    }

                    return {
                        designName: "Sports_7" + sportName,
                        objects: [
                            {
                                text: $scope.school.mascotName,
                                charSpacing: $scope.getMeasurementByInterval(0),
                                left: $scope.getMeasurementByInterval(10),
                                fontFamily: "Fugaz One",
                                top: $scope.getMeasurementByInterval(4),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                stroke: $scope.school.mainColour,
                                strokeWidth: $scope.getMeasurementByInterval(0.1),
                                fontSize: $scope.getMeasurementByInterval(4),
                                width: $scope.getMeasurementByInterval(8)
                            },
                            {
                                text: sportName,
                                charSpacing: $scope.getMeasurementByInterval(64),
                                left: $scope.getMeasurementByInterval(10),
                                fontFamily: "Fugaz One",
                                strokeWidth: $scope.getMeasurementByInterval(0),
                                stroke: transparent,
                                top: $scope.getMeasurementByInterval(7),
                                fill: $scope.school.mainColour,
                                textAlign: "center",
                                fontSize: $scope.getMeasurementByInterval(1.25),
                                width: $scope.getMeasurementByInterval(8)
                            },
                            objectFill
                        ]
                    }
                }

                function createClubCategory(categoryName, icon1, icon2) {
                    categoryName = categoryName.toUpperCase()
                    return {
                        name: categoryName,
                        designs: [createClubOneDesign(categoryName, icon1), createClubTwoDesign(categoryName, icon1), createClubThreeDesign(categoryName, icon2), createClubFourDesign(categoryName, icon2), createClubFiveDesign(categoryName, icon1), createClubSixDesign(categoryName), createClubSevenDesign(categoryName)]
                    }
                }


                function createGradCategory() {
                    return {
                        name: "Graduation",
                        designs: [
                            createGradDesignEight(), createGradDesignOne(), createGradDesignTwo(), createGradDesignThree(), createGradDesignFour(), createGradDesignFive(), createGradDesignSix(), createGradDesignSeven(), createGradDesignNine(), createGradDesignTen()
                        ]
                    }
                }


                function createSportCategory(categoryName, icon1, noFill) {
                    categoryName = categoryName.toUpperCase()
                    return {
                        name: categoryName,
                        designs: [

                            createSportsTwoDesign(categoryName), createSportsThreeDesign(categoryName, icon1), createSportsFourDesign(categoryName, icon1, noFill), createSportsFiveDesign(categoryName), createSportsSixDesign(categoryName), createSportsSevenDesign(categoryName, icon1, noFill), createSportsOneDesign(categoryName)
                        ]
                    }
                }


                $scope.categories = [
                    createGradCategory(),
                    createSportCategory("Athletics", $scope.school.logoUrl, true),
                    createSportCategory("Basketball", "basketball"),
                    createSportCategory("Volleyball", "volleyball"),
                    createSportCategory("Track and Field", "track"),
                    createSportCategory("Ultimate Frisbee", "frisbee"),
                    createSportCategory("Soccer", "soccer"),
                    createSportCategory("Field Hockey", "fieldhockey"),
                    createSportCategory("Badminton", "badminton"),
                    createClubCategory("Choir", "choir", "choir"),
                    createClubCategory("Drama", "theatre-masks", "theatre-masks"),
                    createClubCategory("Strings", "strings", "strings_flipped"),
                    createClubCategory("Band", "trumpet", "trumpet_sideways"),
                    createClubCategory("Chess", "horse", "chess")
                ]

                function loadDesigns() {
                    _.each($scope.categories, function (category) {
                        _.each(category.designs, function (design) {
                            var newCanvas = $scope.createAndAddCanvas(design.designName, category.name)
                            newCanvas.on('mouse:down', function (options) {
                                $scope.school.designOnClick(newCanvas)
                            });
                            newCanvas.hoverCursor = 'pointer';
                            $scope.addDesignToCanvas(design, newCanvas, width, false)
                        })
                    })
                }

                // loadingScreen("Loading Fonts")
                WebFont.load({
                    google: {
                        families: fontsToLoad
                    },
                    // custom: {
                    //     families: ["Athletic"]
                    // },
                    active: function () {
                        var todaysDate = new Date()
                        jQuery.get('https://schoolportals.s3.us-west-2.amazonaws.com/' + $scope.school.logoUrl + ".svg?" + todaysDate.getSeconds(), function (data) {
                            var svgString = new XMLSerializer().serializeToString(data.documentElement);
                            svgVariables[$scope.school.logoUrl] = svgString
                            render_xml("displayLogo", svgVariables[$scope.school.logoUrl])
                            // closeLoadingScreen()
                            loadDesigns()
                        });
                    },
                    inactive: function () {
                        // closeLoadingScreen()
                    },
                    fontinactive: function () {
                        // closeLoadingScreen()
                    }
                });

                // setTimeout(function(){ _.each($scope.categories, function(category){
                //     _.each(category.designs, function(design){
                //         var newCanvas = $scope.createAndAddCanvas(design.designName, category.name)
                //         newCanvas.on('mouse:down', function(options) {
                //             $scope.school.designOnClick(newCanvas)
                //         });
                //         newCanvas.hoverCursor = 'pointer';
                //         $scope.addDesignToCanvas(design,newCanvas, width, false)
                //     })
                // }) }, 100);


                $scope.addDesignToCanvas = function (design, canvas, widthOfMeasurement, selectable) {
                    _.each(design.objects, function (object) {
                        if (object.link) {
                            fabric.loadSVGFromString(svgVariables[object.link], function (objects, options) {
                                if (object.fill) {
                                    _.each(objects, function (objectInGroup) {
                                        objectInGroup.setColor(object.fill)
                                    })
                                }

                                var group = fabric.util.groupSVGElements(objects, {});
                                group.scaleX = (widthOfMeasurement / group.width) * (object.scale / widthOfMeasurement)
                                group.scaleY = (widthOfMeasurement / group.width) * (object.scale / widthOfMeasurement)
                                group.left = object.left - ((group.width * group.scaleX) / 2)
                                group.top = object.top - ((group.height * group.scaleY) / 2)
                                group.selectable = selectable
                                if (object.fill) {
                                    group.setColor(object.fill)
                                }
                                canvas.add(group);
                            });
                        } else if (object.text) {
                            var text = new fabric.Text(object.text, {
                                left: object.left,
                                top: object.top,
                                fontFamily: object.fontFamily,
                                fontSize: object.fontSize,
                                fill: object.fill,
                                stroke: object.stroke,
                                strokeWidth: object.strokeWidth,
                                charSpacing: object.charSpacing,
                                selectable: selectable,
                                textAlign: object.textAlign
                            });
                            text.scaleX = object.width / text.width

                            text.left = object.left - (object.width / 2)
                            text.top = object.top - ((text.fontSize) / 2)
                            canvas.add(text)
                        }
                    })
                    canvas.renderAll();
                }
            }
        };
    })

})();