/**
 * Created by eddisonng on 2017-05-12.
 */

// All the code in this module is
// enclosed in closure
(function(exports) {

    var jan = 0, feb = 1, mar = 2, apr = 3, may = 4, jun = 5, jul = 6, aug = 7, sep = 8, oct = 9, nov = 10, dec = 11


    //BC : https://www.statutoryholidays.com/bc.php
    //ON : https://www.statutoryholidays.com/ontario.php

    var statHolidays2016 = [    //month/day/year/vancouver/toronto
        [jan, 2, 2017 ],
        [feb, 8, 2017],
        [mar, 25, 2017],
        [mar, 28, 2017],
        [apr, 22, 2017],
        [jun, 1, 2017],
        [jul, 1, 2017],
        [aug, 4, 2017],
        [oct, 9, 2017],
        [nov, 11, 2017],
        [dec, 25, 2017],
        [dec, 26, 2017],
        [jan, 1, 2018],
        [feb, 12, 2018],
        [mar, 30, 2018],
        [may, 21, 2018],
        [jul, 2, 2018],
        [aug, 6, 2018],
        [sep, 3, 2018],
        [oct, 8, 2018],
        [nov, 12, 2018],
        [dec, 25, 2018],
        [jan, 1, 2019],
        [feb, 11, 2019],
        [apr, 19, 2019],
        [may, 20, 2019],
        [jul, 1, 2019],
        [aug, 5, 2019],
        [sep, 2, 2019],
        [oct, 14, 2019],
        [nov, 11, 2019],
        [dec, 25, 2019],
        [jan, 1, 2020],
        [feb, 17, 2020],
        [apr, 10, 2020],
        [may, 18, 2020],
        [jul, 1, 2020],
        [aug, 3, 2020],
        [sep, 7, 2020],
        [oct, 12, 2020],
        [nov, 11, 2020],
        [dec, 25, 2020],
        [jan, 1, 2021],
        [feb, 15, 2021],
        [apr, 2, 2021],
        [may, 24, 2021],
        [jul, 1, 2021],
        [aug, 2, 2021],
        [sep, 6, 2021],
        [oct, 11, 2021],
        [nov, 11, 2021],
        [dec, 25, 2021],
        [dec, 27, 2021],
        [jan, 3, 2022, "New Years Day"],
        [feb, 21, 2022, "Family Day"],
        [apr, 15, 2022, "Good Friday"],
        [may, 23, 2022, "Victoria Day"],
        [jul, 1, 2022,"Canada Day"],
        [aug, 8, 2022,"BC Day"],
        [sep, 5, 2022,"Labour Day"],
        [oct, 10, 2022,"Thanksgiving"],
        [nov, 11, 2022,"Remembrance Day"],
        [dec, 26, 2022,"Christmas Day"],
        [jan, 2, 2023, "New Years Day", true, true],
        [feb, 20, 2023, "Family Day", true, true],
        [apr, 7, 2023, "Good Friday", true, true],
        [may, 22, 2023, "Victoria Day", true, true],
        [jul, 3, 2023,"Canada Day", true, true],
        [aug, 7, 2023,"BC Day", true, true],
        [sep, 4, 2023,"Labour Day", true, true],
        [oct, 2, 2023,"National Day for Truth and Reconciliation", true, true],
        [oct, 9, 2023,"Thanksgiving", true, true],
        [nov, 13, 2023,"Remembrance Day", true, true],
        [dec, 25, 2023,"Christmas Day", true, true],
        [dec, 26, 2023,"Boxing Day", true, true],
        [jan, 1, 2024, "New Years Day", true, true],
        [feb, 19, 2024, "Family Day", true, true],
        [mar, 29, 2024, "Good Friday", true, true],
        [may, 20, 2024, "Victoria Day", true, true],
        [jul, 1, 2024,"Canada Day", true, true],
        [aug, 5, 2024,"BC Day / Civic Holiday", true, true],
        [sep, 2, 2024,"Labour Day", true, true],
        [sep, 30, 2024,"National Day for Truth and Reconciliation", true, false],
        [oct, 14, 2024,"Thanksgiving", true, true],
        [nov, 11, 2024,"Remembrance Day", true, false],
        [dec, 25, 2024,"Christmas Day", true, true],
        [dec, 26, 2024,"Boxing Day", false, true]
    ];

    // for db
    var statHolidayDates = statHolidays2016.map(function(dateObject) {
        return {
            $dateFromParts: {
                year: dateObject[2],
                month: dateObject[0] + 1,
                day: dateObject[1]
            }
        }
    });

    function getBusinessDaysLeft(businessDays,startDate,comparisonDate) {

        var tmp = new Date(startDate);
        for(var businessDays = businessDays;businessDays > 0;){
            tmp.setDate( tmp.getDate() + 1);
            if (!(tmp.getDay()== 0 || tmp.getDay() == 6|| isStatHoliday(tmp.getMonth(),tmp.getDate(), tmp.getFullYear()))){
                businessDays--
            }
        }
        var date = new Date()
        if (comparisonDate){
            date = new Date(comparisonDate)
        }
        var businessDaysLeft = 0;

        if (date < tmp) {
            while(!(tmp.getFullYear() == date.getFullYear() && tmp.getDate()==date.getDate() && tmp.getMonth()== date.getMonth())){
                tmp.setDate( tmp.getDate() - 1);
                if ((tmp.getDay()== 0 || tmp.getDay() == 6|| isStatHoliday(tmp.getMonth(),tmp.getDate(),tmp.getFullYear()))){
                    //do nothing
                } else{
                    businessDaysLeft = businessDaysLeft + 1;
                }
            }
        } else {
            while(!(tmp.getFullYear() == date.getFullYear() &&  date.getDate()==tmp.getDate() && date.getMonth()== tmp.getMonth())){
                date.setDate( date.getDate() - 1);
                if ((date.getDay()== 0 || date.getDay() == 6|| isStatHoliday(date.getMonth(),date.getDate(),date.getFullYear()))){
                    //do nothing
                } else{
                    businessDaysLeft = businessDaysLeft - 1;
                }
            }

        }

        return businessDaysLeft
    }

    function isStatHoliday(month, day, year) {
        for (var i = 0; i < statHolidays2016.length; i++) {
            if (statHolidays2016[i][0] == month && statHolidays2016[i][1] == day && statHolidays2016[i][2] == year) {
                return true
            }
        }
        return false;
    }

    function addBusinessDayUnformatted(businessDays, startDate) {
        var tmp = new Date(startDate);

        for (var businessDays = businessDays; businessDays > 0;) {
            tmp.setDate(tmp.getDate() + 1);
            if (!(tmp.getDay() == 6 || tmp.getDay() == 0 || isStatHoliday(tmp.getMonth(), tmp.getDate(), tmp.getFullYear()))) {
                businessDays = businessDays - 1;
            }
        }
        return tmp;
    }


    function addBusinessDays(businessDays, startDate, format) {

        var tmp = addBusinessDayUnformatted(businessDays, startDate);
        if (!format){
            format = "ddd MMM Do YYYY"
        }

        var tmpString = moment(tmp).format(format);
        return tmpString;

    }

    // Export the function to exports
    // In node.js this will be exports
    // the module.exports
    // In browser this will be function in
    // the global object sharedModule
    exports.isStatHoliday = isStatHoliday;
    exports.statHolidays2016 = statHolidays2016;
    exports.statHolidayDates = statHolidayDates;
    exports.addBusinessDayUnformatted = addBusinessDayUnformatted;
    exports.addBusinessDays = addBusinessDays;
    exports.getBusinessDaysLeft = getBusinessDaysLeft;

})(typeof exports === 'undefined'?
    this['dateService']={}: exports);

