(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('jsonld', ['$filter', '$sce', function ($filter, $sce) {
            return {
                restrict: 'E',
                template: function () {
                    return '<script type="application/ld+json" ng-bind-html="onGetJson()"></script>';
                },
                scope: {
                    json: '=json'
                },
                link: function ($scope, element, attrs) {
                    $scope.onGetJson = function () {
                        if ($scope.json) {
                            return $sce.trustAsHtml($filter('json')($scope.json));
                        }
                    }
                }
            };
        }])
})();