(function () {
    'use strict';


    angular.module('landingPageModule', [])
        .directive('landingPage', LandingPageDirective)
        .directive('bannerSection', BannerSectionDirective)
        .directive('leftAlignedImageSection', LeftAlignedImageSection)
        .directive('leftAlignedTextSection', LeftAlignedTextSection)
        .directive('shippingValuePropSection', ShippingValuePropSection)
        .directive('faqSection', FAQSection)
        .directive('imageRowSection', ImageRowSection)
        .directive('leftColumnSection', LeftColumnSection)
        .directive('rightColumnSection', RightColumnSection)
        .directive('productListSection', ProductListSection)
        .directive('valuePropSection', ValuePropositionSection)
        .directive('turnaroundPropSection', TurnaroundPropositionSection)
        .directive('featuredProductsSection', FeaturedProductsSectionDirective)
        // Static
        .directive('merchSection', MerchSection)
        .directive('clientsSection', ClientsSection)
        .directive('reviewsSection', ReviewsSection)
        .directive('brandsSection', BrandsSection)
        .directive('popularCategoriesSection', PopularCategoriesSection)
        .directive('mapSection', MapSection)
        .directive('designReviewSection', DesignReviewSection)
        .directive('verticalItemList', VerticalItemList)
        .directive('locationSection', LocationSection)
        .directive('ourCompassSection', OurCompassSection)

    // other
        .directive('richtext', RichTextDirective);

    RichTextDirective.$inject = ['$rootScope']

    function RichTextDirective($rootScope) {
        var directive = {
            link: link,
            templateUrl: 'shared/js/widget/pageTemplate/rich-text-directive.html',
            restrict: 'EA',
            scope: {
                richHtml: '='
            }
        };
        return directive;

        function link($scope, element, attrs) {
        }
    }

    LandingPageDirective.$inject = ['CommonUtilService', '$rootScope', '$location', '$timeout'];

    function LandingPageDirective(CommonUtilService, $rootScope, $location, $timeout) {
        return {
            restrict: 'EA',
            scope: {
                pageTemplate: '=',
                locationMetadata: '=',
                currentLocale: '=',
                fromAdmin: '=',
            },
            templateUrl: 'shared/js/widget/pageTemplate/landing-page.html',
            link: link
        };

        function link($scope, elem, attr) {
            activate();
            function activate() {
                var HEADER_SECTIONS = [
                    modelService.PAGE_SECTIONS.BANNER,
                    modelService.PAGE_SECTIONS.IMAGE_ROW,
                    modelService.PAGE_SECTIONS.LEFT_ALIGNED_TEXT,
                    modelService.PAGE_SECTIONS.LEFT_COLUMNED,
                    modelService.PAGE_SECTIONS.PRODUCTS,
                    modelService.PAGE_SECTIONS.FEATURED_PRODUCTS
                ]

                var firstIndex = _.findIndex($scope.pageTemplate.sections, function(section) {
                    return section.sectionVisible && HEADER_SECTIONS.includes(section.sectionProps.sectionType);
                })

                var headerIndex;
                var searchIndex = 0;
                while(!_.isNumber(headerIndex)) {
                    if (searchIndex >= $scope.pageTemplate.sections.length) {
                        break;
                    }

                    var sectionToCheck = $scope.pageTemplate.sections[searchIndex]
                    if (!sectionToCheck) {
                        break;
                    }
                    if (sectionToCheck.sectionVisible && HEADER_SECTIONS.includes(sectionToCheck.sectionProps.sectionType)) {
                        if (sectionToCheck.sectionProps.sectionType === modelService.PAGE_SECTIONS.BANNER) {
                            var bannerProps = sectionToCheck.sectionProps;
                            if (bannerProps.sectionHeader.dataProps.textValue) {
                                headerIndex = searchIndex;
                            }
                        } else if (sectionToCheck.sectionProps.sectionType === modelService.PAGE_SECTIONS.IMAGE_ROW) {
                            // sometimes image section has no text, if so the next section should be considered
                            var imageProps = sectionToCheck.sectionProps;
                            if (imageProps.title.dataProps.textValue) {
                                headerIndex = searchIndex;
                            }
                        } else if (sectionToCheck.sectionProps.sectionType === modelService.PAGE_SECTIONS.LEFT_ALIGNED_TEXT) {
                            var textProps = sectionToCheck.sectionProps;
                            if (textProps.sectionTitle.dataProps.textValue) {
                                headerIndex = searchIndex;
                            }
                        } else if (sectionToCheck.sectionProps.sectionType === modelService.PAGE_SECTIONS.FEATURED_PRODUCTS) {
                            if (!$location.search().viewAll) {
                                headerIndex = searchIndex;
                            }
                        } else {
                            headerIndex = searchIndex;
                        }
                    }
                    searchIndex++;
                }

                if(_.isNumber(headerIndex)) {
                    $scope.headerSectionIndex = headerIndex;
                } else {
                    $scope.headerSectionIndex = _.findIndex($scope.pageTemplate.sections, function(section) {
                        return section.sectionVisible;
                    })
                }
            }

            $scope.whenSectionReady = function(index) {
                    // console.log('Loaded: section ' + index + ' ' + $scope.pageTemplate.sections[index].sectionProps.sectionType);
                    if (index === $scope.headerSectionIndex) {
                        $timeout(function () {
                            $scope.$emit('headerSectionLoaded');
                        })
                    }
            }

            $scope.sectionStyles = function(sectionStyles) {
                var styles = {}
                if (sectionStyles.backgroundColour) {
                    styles['background-color'] = sectionStyles.backgroundColour
                }
                return styles;
            }
        }
    }

    FeaturedProductsSectionDirective.$inject = ['ProductService', 'CommonUtilService', '$rootScope', '$location']

    function FeaturedProductsSectionDirective(ProductService, CommonUtilService, $rootScope, $location) {
        return {
            restrict: 'EA',
            scope: {
                featuredProductsProps: '=',
                isHeading: '=',
            },
            templateUrl: 'shared/js/widget/pageTemplate/featured-products-section.html',
            link: function($scope, elem, attr){
                $scope.compiledProducts = undefined;
                $scope.productsLoaded = false;
                $scope.initialColoursCount = 12;
                $scope.initialDescriptionsCount = 5;
                $scope.props  = {
                    fetchingQueue: Date.now(),
                    hideWidget: false
                }

                function activate() {
                    $scope.$on('productsLoaded', function(){
                        $scope.productsLoaded = true;
                        // console.log('products loaded');
                    })

                    // show on query param
                    var shouldViewAllOverride = !_.isUndefined($location.search().viewAll);
                    if (shouldViewAllOverride) {
                        $scope.props.hideWidget = shouldViewAllOverride;
                    }
                    fetchProducts();
                    // on
                    // $scope.$watch('featuredProductsProps.skus', function (oldSkus, newSkus) {
                    //     fetchProducts();
                    // }, true)
                }

                function fetchProducts() {
                    var callTime = Date.now();
                    if (callTime >= $scope.props.fetchingQueue) {
                        $scope.props.fetchingQueue = callTime;
                    }
                    var productSkus = _.filter(_.map($scope.featuredProductsProps.skus.dataProps.dataTypeList, function (productDataType) {
                        return productDataType.dataProps.sku;
                    }));

                    if (productSkus.length > 0) {
                        ProductService.fetchProducts({productSkus: productSkus})
                            .then(function (fetchedProducts) {
                                if (callTime >= $scope.props.fetchingQueue) {
                                    $scope.compiledProducts = _.map(productSkus, function (sku, index) {
                                        var foundProduct = _.find(fetchedProducts, function (product) {
                                            return product.sku === sku
                                        });

                                        if (foundProduct) {
                                            foundProduct.viewingColour = 0;
                                            foundProduct.coloursCount = $scope.initialColoursCount;
                                            foundProduct.descriptionsCount = $scope.initialDescriptionsCount;
                                        }
                                        return foundProduct;
                                    })
                                }
                            })
                            .catch(function(error) {
                                $scope.compiledProducts = [];
                            })
                    } else {
                        $scope.compiledProducts = [];
                    }
                }
                activate()
            }
        };
    }

    function BannerSectionDirective($timeout, $rootScope, matchmedia) {
        return {
            restrict: 'EA',
            scope: {
                isHeading: '=',
                bannerProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/banner-section.html',
            link: function($scope){
                $scope.banner = {
                    backgroundImage: ""
                }

                $scope.$watch('bannerProps', function(newValue, oldValue) {
                    if (oldValue) {
                        handleMediaChange();
                    }
                }, true)

                $scope.watchers = [];

                $scope.watchers.push(
                    matchmedia.onPhone(function(mediaQuery) {
                        if(matchmedia.isPhone()) {
                            handleMediaChange();
                        }
                    })
                )

                $scope.watchers.push(
                    matchmedia.onTablet(function(mediaQuery) {
                        if (matchmedia.isTablet()) {
                            handleMediaChange();
                        }
                    })
                )

                $scope.watchers.push(
                    matchmedia.onDesktop(function(mediaQuery) {
                        if (matchmedia.isDesktop()) {
                            handleMediaChange();
                        }
                    })
                )

                function handleMediaChange() {
                    if (matchmedia.isPhone() && $scope.bannerProps.backgroundImageMobile.dataProps.imageUrl) {
                        $scope.banner.deviceType = 'PHONE';
                        $scope.banner.backgroundImage = $scope.bannerProps.backgroundImageMobile.dataProps.imageUrl;
                    } else if (matchmedia.isTablet() && $scope.bannerProps.backgroundImageTablet.dataProps.imageUrl) {
                        $scope.banner.deviceType = 'TABLET';
                        $scope.banner.backgroundImage = $scope.bannerProps.backgroundImageTablet.dataProps.imageUrl;
                    } else {
                        $scope.banner.deviceType = 'DESKTOP';
                        $scope.banner.backgroundImage = $scope.bannerProps.backgroundImage.dataProps.imageUrl;
                    }
                    $scope.banner.noImageSrcSet = $scope.banner.deviceType === 'DESKTOP';
                    $scope.banner.backgroundImage = $rootScope.compressImage($scope.banner.backgroundImage);
                    // update scope after digest
                    $timeout(function() {
                       $scope.$apply()
                    })
                }

                $scope.$on('$destroy', function() {
                    _.forEach($scope.watchers, function(watcher){
                        watcher();
                    })
                })

                // banner styles
                $scope.mainBannerStyle = function() {
                    return {
                        'padding-top': !$scope.banner.backgroundImage ? '1vh' : '',
                        'padding-bottom': !$scope.banner.backgroundImage ? '1vh' : '',
                    }
                }
            }
        };
    }

    function LeftAlignedImageSection() {
        return {
            restrict: 'EA',
            scope: {
                laiProps: '=',
            },
            templateUrl: 'shared/js/widget/pageTemplate/left-aligned-image-section.html',
            link: function($scope){
                $scope.isBlogStyle = {};
                $scope.imageContainerStyle = {};
                $scope.aspectRatioStyle = {};

                try {
                    var isBlog = $scope.laiProps.isBlog.dataProps.booleanValue;
                    if (isBlog) {
                        $scope.isBlogStyle = {
                            'margin': 'auto'
                        }
                    }
                    var imageWidth = $scope.laiProps.imageWidth.dataProps.textValue;
                    var imageHeight = $scope.laiProps.imageHeight.dataProps.textValue;



                    if (imageWidth && imageHeight) {
                        $scope.imageContainerStyle = {
                            'max-width' : imageWidth + 'px',
                            'max-height' : imageHeight + 'px',
                        }
                        var aspectRatio = Number(imageHeight) / Number(imageWidth);
                        if (aspectRatio) {
                            $scope.aspectRatioStyle = {
                                'padding-bottom': 'calc(' + aspectRatio + ' * 100%)'
                            }
                        }
                    }

                } catch(e) {

                }

            }
        };
    }

    function LeftAlignedTextSection() {
        return {
            restrict: 'EA',
            scope: {
                latProps: '=',
                isHeading: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/left-aligned-text-section.html',
            link: function($scope){
                try {
                    var isBlog = $scope.latProps.isBlog.dataProps.booleanValue;
                    if (isBlog) {
                        $scope.isBlogStyle = {
                            'margin': 'auto'
                        }
                    }
                } catch(e) {

                }
            }
        };
    }

    function ShippingValuePropSection() {
        return {
            restrict: 'EA',
            scope: {
                shipProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/shipping-value-prop-section.html',
            link : function($scope){
                $scope.getDueDate = function(shippingTimeOverride){
                    var shipTime;
                    if (shippingTimeOverride) {
                        shipTime = parseInt(shippingTimeOverride);
                    } else {
                        shipTime = 3;
                    }
                    var today = new Date()
                    var dueDate = moment(dateService.addBusinessDays(shipTime, today),"ddd MMM Do YYYY")
                    return {
                        month : moment(dueDate).format("MMM"),
                        date : moment(dueDate).format("D")
                    }
                };
            }
        };
    }

    function VerticalItemList() {
        return {
            restrict: 'EA',
            scope: {
                itemListProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/vertical-item-list-section.html',
            link: function($scope){
                $scope.itemList = []

                activate();

                function activate() {
                    getItemList();
                    // $scope.$watch("itemListProps", function() {
                    //     getItemList();
                    // }, true)
                }

                function getItemList() {
                    var images = $scope.itemListProps.images.dataProps.dataTypeList;
                    var headers = $scope.itemListProps.headers.dataProps.dataTypeList;
                    var descriptions = $scope.itemListProps.descriptions.dataProps.dataTypeList;

                    $scope.itemList = _.map(headers, function(header, index) {
                        return {
                            header: header,
                            image: images[index],
                            description: descriptions[index]
                        }
                    });
                }
            }
        };
    }

    function MapSection($rootScope, $sce) {
        return {
            restrict: 'EA',
            scope: {
                mapProps: '=',
                city : '@city',
                province : '@province'
            },
            templateUrl: 'shared/js/widget/pageTemplate/map-section.html',
            link: function($scope){
                // console.log($scope.city)
                // console.log($scope.province)
                $scope.mapMetadata = {
                    mapUrl: "",
                    localPhone: "",
                    street1: "",
                    street2: "",
                    city: "",
                    province: "",
                    zip: "",
                    hours: ""
                }

                var vancouverMapMetadata = {
                    mapUrl:
                        $sce.trustAsResourceUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.7053090474706!2d-123.11972988475807!3d49.20614968411198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486769f38599947%3A0xfab5117abf828aea!2sCoastal%20Reign%20Printing!5e0!3m2!1sen!2sca!4v1613157174603!5m2!1sen!2sca"),
                    localPhone: $rootScope.phoneNumber ? $rootScope.phoneNumber.local : "",
                    street1: "8793 Cambie Street",
                    city: "Vancouver",
                    province: "BC",
                    zip: "V6P3J9",
                    hours: "Monday - Friday | 9:00 AM - 5:00 PM (PST)"
                }

                var torontoMapMetadata = {
                    mapUrl:
                        $sce.trustAsResourceUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11554.753761632093!2d-79.5144381!3d43.6130283!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xebbcec353dd53df7!2sCoastal%20Reign%20Printing%20Ltd!5e0!3m2!1sen!2sca!4v1644950179406!5m2!1sen!2sca"),
                    localPhone: $rootScope.phoneNumber ? $rootScope.phoneNumber.localToronto : "",
                    street1: "310 Judson Street",
                    street2: "Unit 3",
                    city: "Toronto",
                    province: "ON",
                    zip: "M8Z5T6",
                    hours: "Monday - Friday | 9:00 AM - 5:00 PM (EST)"
                }

                var locationOverride = !_.isEmpty($scope.mapProps) ? $scope.mapProps.locationOverride.dataProps.textValue: null;
                if(locationOverride) {
                    if (locationOverride.toLowerCase() == 'vancouver') {
                        $scope.locationInformation = true
                        $scope.mapMetadata = vancouverMapMetadata
                    } else if (locationOverride.toLowerCase() == 'toronto') {
                        $scope.locationInformation = true
                        $scope.mapMetadata = torontoMapMetadata
                    }
                } else {
                    if ($scope.city == "Vancouver" && $scope.province == "BC") {
                        $scope.locationInformation = true
                        $scope.mapMetadata = vancouverMapMetadata
                    }
                    if ($scope.city == "Toronto" && $scope.province == "ON") {
                        $scope.locationInformation = true
                        $scope.mapMetadata = torontoMapMetadata
                    }
                }

                $scope.getStreet = function() {
                    var street2 = $scope.mapMetadata.street2 ? $scope.mapMetadata.street2 + " - ": "";
                    return street2 + $scope.mapMetadata.street1
                }
            }
        };
    }

    function ValuePropositionSection() {
        return {
            restrict: 'EA',
            scope: {
                valueProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/value-prop-section.html',
            link: function(){}
        };
    }

    function TurnaroundPropositionSection() {
        return {
            restrict: 'EA',
            scope: {
                turnaroundProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/turnaround-prop-section.html',
            link: function(){}
        };
    }

    DesignReviewSection.$inject = ['CountryService']
    function DesignReviewSection(CountryService) {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/design-review-section.html',
            link: function($scope){
                $scope.popoverText = CountryService.textFindAndReplace('Our artists will send you over a high quality mockup with the placement, print size and specific colours used in your print. Your final print will match your mockup specifications, guaranteed.');
            }
        };
    }

    function PopularCategoriesSection() {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/popular-categories-section.html',
            link: function($scope){
                $scope.genericCategories =
                    [
                        {
                        "sectionStyles": {
                            "paddingTop": false,
                            "paddingBottom": false
                        },
                        "sectionVisible": true,
                        "sectionDisplayName": "",
                        "sectionProps": {
                            // "title": {
                            //     "commonName": "Title",
                            //     "dataProps": {
                            //         "textValue": "Most Popular Categories",
                            //         "locationTextValue": "",
                            //         "url": "",
                            //         "urlRef": {
                            //             "linkType": "PAGE_TEMPLATE",
                            //             "staticUrl": "",
                            //             "pageTemplateId": "",
                            //             "_id": "6451499deafa0da4c842e88d"
                            //         },
                            //         "_id": "6451499deafa0da4c842e88e",
                            //         "dataType": "TEXT"
                            //     },
                            //     "_id": "6451499deafa0da4c842e88c"
                            // },
                            "hasPadding": {
                                "commonName": "Has Image Padding",
                                "dataProps": {
                                    "booleanValue": true,
                                    "_id": "6451499deafa0da4c842e890",
                                    "dataType": "BOOLEAN"
                                },
                                "_id": "6451499deafa0da4c842e88f"
                            },
                            "fitWidth": {
                                "commonName": "Should Stretch Images",
                                "dataProps": {
                                    "booleanValue": false,
                                    "_id": "6451499deafa0da4c842e892",
                                    "dataType": "BOOLEAN"
                                },
                                "_id": "6451499deafa0da4c842e891"
                            },
                            "images": {
                                "commonName": "Images",
                                "dataProps": {
                                    "dataTypeList": [
                                        {
                                            "commonName": "Image",
                                            "dataProps": {
                                                "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Stormtech%20Shirts_1683153552764.jpg",
                                                "hyperLinkRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "62d058ffb7d1260004c67272",
                                                    "_id": "645150e756c86353b6f9cc74"
                                                },
                                                "imageHyperLink": "t-shirt-printing-canada",
                                                "altText": "An image of custom t-shirts that you can print your logo or design on!",
                                                "locationAltText": "",
                                                "_id": "645150e756c86353b6f9cc73",
                                                "dataType": "IMAGE"
                                            },
                                            "_id": "645149a2da70923acea9c2dd"
                                        },
                                        {
                                            "commonName": "Image",
                                            "dataProps": {
                                                "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Jerzees%20Hoodies%20and%20Sweatshirts__1683064248197.jpg",
                                                "hyperLinkRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "620c24b4f13f380004004ce2",
                                                    "_id": "645150e756c86353b6f9cc78"
                                                },
                                                "imageHyperLink": "custom-products/custom-clothing/hoodies-sweaters/hoodies",
                                                "altText": "An image of custom hoodies and crewnecks that you can print your logo or design on!",
                                                "locationAltText": "",
                                                "_id": "645150e756c86353b6f9cc77",
                                                "dataType": "IMAGE"
                                            },
                                            "_id": "645149a3da70923acea9c2de"
                                        },
                                        {
                                            "commonName": "Image",
                                            "dataProps": {
                                                "imageUrl": "https://s3.amazonaws.com/file-uploads-cr/Hats_1691771024734.jpg",
                                                "hyperLinkRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "620598d5759d9c0004806478",
                                                    "_id": "645150e756c86353b6f9cc7c"
                                                },
                                                "imageHyperLink": "custom-products/custom-clothing/hats",
                                                "altText": "An image of custom hats that you can embroider your logo or design on!",
                                                "locationAltText": "",
                                                "_id": "645150e756c86353b6f9cc7b",
                                                "dataType": "IMAGE"
                                            },
                                            "_id": "645149a4da70923acea9c2df"
                                        },
                                        {
                                            "commonName": "Image",
                                            "dataProps": {
                                                "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Ecofriendly%20tote%20bags_1683050415972.jpg",
                                                "hyperLinkRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "6204123e96de870004f33274",
                                                    "_id": "645150e756c86353b6f9cc80"
                                                },
                                                "imageHyperLink": "custom-products/custom-bags/tote-bags",
                                                "altText": "An image of custom tote bags that you can print your logo or design on!",
                                                "locationAltText": "",
                                                "_id": "645150e756c86353b6f9cc7f",
                                                "dataType": "IMAGE"
                                            },
                                            "_id": "645149a4da70923acea9c2e0"
                                        }
                                    ],
                                    "maxLength": 4,
                                    "listDataType": "IMAGE",
                                    "_id": "6451499deafa0da4c842e894",
                                    "dataType": "LIST"
                                },
                                "_id": "6451499deafa0da4c842e893"
                            },
                            "labels": {
                                "commonName": "Image Labels",
                                "dataProps": {
                                    "dataTypeList": [
                                        {
                                            "commonName": "Text",
                                            "dataProps": {
                                                "textValue": "T-Shirts",
                                                "locationTextValue": "",
                                                "url": "t-shirt-printing-canada",
                                                "urlRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "62d058ffb7d1260004c67272",
                                                    "_id": "645150e756c86353b6f9cc86"
                                                },
                                                "_id": "645150e756c86353b6f9cc85",
                                                "dataType": "TEXT"
                                            },
                                            "_id": "64514fb6da70923acea9c2e1"
                                        },
                                        {
                                            "commonName": "Text",
                                            "dataProps": {
                                                "textValue": "Hoodies and Crewnecks",
                                                "locationTextValue": "",
                                                "url": "custom-products/custom-clothing/hoodies-sweaters/hoodies",
                                                "urlRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "620c24b4f13f380004004ce2",
                                                    "_id": "645150e756c86353b6f9cc8a"
                                                },
                                                "_id": "645150e756c86353b6f9cc89",
                                                "dataType": "TEXT"
                                            },
                                            "_id": "64514fb6da70923acea9c2e2"
                                        },
                                        {
                                            "commonName": "Text",
                                            "dataProps": {
                                                "textValue": "Hats",
                                                "locationTextValue": "",
                                                "url": "custom-products/custom-clothing/hats",
                                                "urlRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "620598d5759d9c0004806478",
                                                    "_id": "645150e756c86353b6f9cc8e"
                                                },
                                                "_id": "645150e756c86353b6f9cc8d",
                                                "dataType": "TEXT"
                                            },
                                            "_id": "64514fb7da70923acea9c2e3"
                                        },
                                        {
                                            "commonName": "Text",
                                            "dataProps": {
                                                "textValue": "Tote Bags",
                                                "locationTextValue": "",
                                                "url": "custom-products/custom-bags/tote-bags",
                                                "urlRef": {
                                                    "linkType": "PAGE_TEMPLATE",
                                                    "staticUrl": "",
                                                    "pageTemplateId": "6204123e96de870004f33274",
                                                    "_id": "645150e756c86353b6f9cc92"
                                                },
                                                "_id": "645150e756c86353b6f9cc91",
                                                "dataType": "TEXT"
                                            },
                                            "_id": "64514fb7da70923acea9c2e4"
                                        }
                                    ],
                                    "maxLength": 4,
                                    "listDataType": "TEXT",
                                    "_id": "6451499deafa0da4c842e896",
                                    "dataType": "LIST"
                                },
                                "_id": "6451499deafa0da4c842e895"
                            },
                            "cta": {
                                "commonName": "Call to Action Button",
                                "dataProps": {
                                    "textValue": "",
                                    "locationTextValue": "",
                                    "url": "",
                                    "urlRef": {
                                        "linkType": "PAGE_TEMPLATE",
                                        "staticUrl": "",
                                        "pageTemplateId": "",
                                        "_id": "6451499deafa0da4c842e898"
                                    },
                                    "_id": "6451499deafa0da4c842e899",
                                    "dataType": "TEXT"
                                },
                                "_id": "6451499deafa0da4c842e897"
                            },
                            "_id": "6451499deafa0da4c842e89a",
                            "sectionType": "ImageRow"
                        },
                        "_id": "6451499deafa0da4c842e88b"
                    },
                        {
                            "sectionStyles": {
                                "paddingTop": false,
                                "paddingBottom": false
                            },
                            "sectionVisible": true,
                            "sectionDisplayName": "",
                            "sectionProps": {
                                "title": {
                                    "commonName": "Title",
                                    "dataProps": {
                                        "textValue": "",
                                        "locationTextValue": "",
                                        "url": "",
                                        "urlRef": {
                                            "linkType": "PAGE_TEMPLATE",
                                            "staticUrl": "",
                                            "pageTemplateId": "",
                                            "_id": "64514fcc56c86353b6f9c780"
                                        },
                                        "_id": "64514fcc56c86353b6f9c781",
                                        "dataType": "TEXT"
                                    },
                                    "_id": "64514fcc56c86353b6f9c77f"
                                },
                                "hasPadding": {
                                    "commonName": "Has Image Padding",
                                    "dataProps": {
                                        "booleanValue": true,
                                        "_id": "64514fcc56c86353b6f9c783",
                                        "dataType": "BOOLEAN"
                                    },
                                    "_id": "64514fcc56c86353b6f9c782"
                                },
                                "fitWidth": {
                                    "commonName": "Should Stretch Images",
                                    "dataProps": {
                                        "booleanValue": false,
                                        "_id": "64514fcc56c86353b6f9c785",
                                        "dataType": "BOOLEAN"
                                    },
                                    "_id": "64514fcc56c86353b6f9c784"
                                },
                                "images": {
                                    "commonName": "Images",
                                    "dataProps": {
                                        "dataTypeList": [
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Coal%20Harbour%20Jackets_1683064324620.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "620d8ca9ab3f1f0004c3f74b",
                                                        "_id": "645150e756c86353b6f9ccb4"
                                                    },
                                                    "imageHyperLink": "custom-products/custom-clothing/jackets",
                                                    "altText": "An image of custom jackets that you can print your logo or design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccb3",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64514fd1da70923acea9c2e5"
                                            },
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Vests%20copy_1687556439978.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "620ea3b02fa05a00044e0cc0",
                                                        "_id": "645150e756c86353b6f9ccc0"
                                                    },
                                                    "imageHyperLink": "custom-products/custom-clothing/jackets/vests",
                                                    "altText": "An image of custom vests that you can print your logo or design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccbf",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64514fd3da70923acea9c2e8"
                                            },
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Custom%20Sport%20jerseys_1683050491889.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "620ab37539f24100049eae53",
                                                        "_id": "645150e756c86353b6f9ccb8"
                                                    },
                                                    "imageHyperLink": "custom-products/custom-athletics/jerseys",
                                                    "altText": "An image of custom jerseys that you can print your team's design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccb7",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64514fd2da70923acea9c2e6"
                                            },
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/drinkware_1683050508538.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "62054f6d96de870004f69bf4",
                                                        "_id": "645150e756c86353b6f9ccbc"
                                                    },
                                                    "imageHyperLink": "custom-products/promotional/drinkware",
                                                    "altText": "An image of custom drinkware that you can print your logo or design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccbb",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64514fd2da70923acea9c2e7"
                                            }
                                        ],
                                        "maxLength": 4,
                                        "listDataType": "IMAGE",
                                        "_id": "64514fcc56c86353b6f9c787",
                                        "dataType": "LIST"
                                    },
                                    "_id": "64514fcc56c86353b6f9c786"
                                },
                                "labels": {
                                    "commonName": "Image Labels",
                                    "dataProps": {
                                        "dataTypeList": [
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Jackets",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/custom-clothing/jackets",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "620d8ca9ab3f1f0004c3f74b",
                                                        "_id": "645150e756c86353b6f9ccc6"
                                                    },
                                                    "_id": "645150e756c86353b6f9ccc5",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "6451501dda70923acea9c2e9"
                                            },
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Vests",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/custom-clothing/jackets/vests",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "620ea3b02fa05a00044e0cc0",
                                                        "_id": "645150e756c86353b6f9ccd2"
                                                    },
                                                    "_id": "645150e756c86353b6f9ccd1",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "6451501eda70923acea9c2ec"
                                            },
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Jerseys",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/custom-athletics/jerseys",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "620ab37539f24100049eae53",
                                                        "_id": "645150e756c86353b6f9ccca"
                                                    },
                                                    "_id": "645150e756c86353b6f9ccc9",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "6451501dda70923acea9c2ea"
                                            },
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Drinkware",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/promotional/drinkware",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "62054f6d96de870004f69bf4",
                                                        "_id": "645150e756c86353b6f9ccce"
                                                    },
                                                    "_id": "645150e756c86353b6f9cccd",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "6451501eda70923acea9c2eb"
                                            }
                                        ],
                                        "maxLength": 4,
                                        "listDataType": "TEXT",
                                        "_id": "64514fcc56c86353b6f9c789",
                                        "dataType": "LIST"
                                    },
                                    "_id": "64514fcc56c86353b6f9c788"
                                },
                                "cta": {
                                    "commonName": "Call to Action Button",
                                    "dataProps": {
                                        "textValue": "",
                                        "locationTextValue": "",
                                        "url": "",
                                        "urlRef": {
                                            "linkType": "PAGE_TEMPLATE",
                                            "staticUrl": "",
                                            "pageTemplateId": "",
                                            "_id": "64514fcc56c86353b6f9c78b"
                                        },
                                        "_id": "64514fcc56c86353b6f9c78c",
                                        "dataType": "TEXT"
                                    },
                                    "_id": "64514fcc56c86353b6f9c78a"
                                },
                                "_id": "64514fcc56c86353b6f9c78d",
                                "sectionType": "ImageRow"
                            },
                            "_id": "64514fcc56c86353b6f9c77e"
                        },
                        {
                            "sectionStyles": {
                                "paddingTop": false,
                                "paddingBottom": false
                            },
                            "sectionVisible": true,
                            "sectionDisplayName": "",
                            "sectionProps": {
                                "title": {
                                    "commonName": "Title",
                                    "dataProps": {
                                        "textValue": "",
                                        "locationTextValue": "",
                                        "url": "",
                                        "urlRef": {
                                            "linkType": "PAGE_TEMPLATE",
                                            "staticUrl": "",
                                            "pageTemplateId": "",
                                            "_id": "6451502e56c86353b6f9c8e4"
                                        },
                                        "_id": "6451502e56c86353b6f9c8e5",
                                        "dataType": "TEXT"
                                    },
                                    "_id": "6451502e56c86353b6f9c8e3"
                                },
                                "hasPadding": {
                                    "commonName": "Has Image Padding",
                                    "dataProps": {
                                        "booleanValue": true,
                                        "_id": "6451502e56c86353b6f9c8e7",
                                        "dataType": "BOOLEAN"
                                    },
                                    "_id": "6451502e56c86353b6f9c8e6"
                                },
                                "fitWidth": {
                                    "commonName": "Should Stretch Images",
                                    "dataProps": {
                                        "booleanValue": false,
                                        "_id": "6451502e56c86353b6f9c8e9",
                                        "dataType": "BOOLEAN"
                                    },
                                    "_id": "6451502e56c86353b6f9c8e8"
                                },
                                "images": {
                                    "commonName": "Images",
                                    "dataProps": {
                                        "dataTypeList": [
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Made%20in%20Canada%20clothing_1683050559479.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "6299256ccd60680004114c74",
                                                        "_id": "645150e756c86353b6f9ccf4"
                                                    },
                                                    "imageHyperLink": "custom-products/custom-clothing/made-in-canada",
                                                    "altText": "An image of custom made in Canada products that you can print your logo or design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccf3",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64515034da70923acea9c2ed"
                                            },
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Christmas%20Gift_1683064440263.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "62f294c5302a8c000491ad87",
                                                        "_id": "645150e756c86353b6f9ccf8"
                                                    },
                                                    "imageHyperLink": "custom-products/promotional/company-swag-box",
                                                    "altText": "An image of custom swag boxes that you can build and order for your company!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccf7",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64515035da70923acea9c2ee"
                                            },
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Ecofriendly_1687556390798.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "624df82ce2ef050004a677d5",
                                                        "_id": "645150e756c86353b6f9ccfc"
                                                    },
                                                    "imageHyperLink": "custom-products/eco-friendly-products",
                                                    "altText": "An image of eco friendly sign, we offer a variety of eco friendly products that you can print your logo or design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccfb",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64515035da70923acea9c2ef"
                                            },
                                            {
                                                "commonName": "Image",
                                                "dataProps": {
                                                    "imageUrl": "https://file-uploads-cr.s3.amazonaws.com/Notebooks_1683820761894.jpg",
                                                    "hyperLinkRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "6244d518194fac0004a2aad2",
                                                        "_id": "645150e756c86353b6f9cd00"
                                                    },
                                                    "imageHyperLink": "custom-products/promotional/office-supplies/notebooks",
                                                    "altText": "An image of custom notebooks that you can print your logo or design on!",
                                                    "locationAltText": "",
                                                    "_id": "645150e756c86353b6f9ccff",
                                                    "dataType": "IMAGE"
                                                },
                                                "_id": "64515036da70923acea9c2f0"
                                            }
                                        ],
                                        "maxLength": 4,
                                        "listDataType": "IMAGE",
                                        "_id": "6451502e56c86353b6f9c8eb",
                                        "dataType": "LIST"
                                    },
                                    "_id": "6451502e56c86353b6f9c8ea"
                                },
                                "labels": {
                                    "commonName": "Image Labels",
                                    "dataProps": {
                                        "dataTypeList": [
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Made In Canada",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/custom-clothing/made-in-canada",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "6299256ccd60680004114c74",
                                                        "_id": "645150e756c86353b6f9cd06"
                                                    },
                                                    "_id": "645150e756c86353b6f9cd05",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "645150d3da70923acea9c2f1"
                                            },
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Swag Boxes",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/promotional/company-swag-box",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "62f294c5302a8c000491ad87",
                                                        "_id": "645150e756c86353b6f9cd0a"
                                                    },
                                                    "_id": "645150e756c86353b6f9cd09",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "645150d4da70923acea9c2f2"
                                            },
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Eco-Friendly",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/eco-friendly-products",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "624df82ce2ef050004a677d5",
                                                        "_id": "645150e756c86353b6f9cd0e"
                                                    },
                                                    "_id": "645150e756c86353b6f9cd0d",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "645150d4da70923acea9c2f3"
                                            },
                                            {
                                                "commonName": "Text",
                                                "dataProps": {
                                                    "textValue": "Notebooks",
                                                    "locationTextValue": "",
                                                    "url": "custom-products/promotional/office-supplies/notebooks",
                                                    "urlRef": {
                                                        "linkType": "PAGE_TEMPLATE",
                                                        "staticUrl": "",
                                                        "pageTemplateId": "6244d518194fac0004a2aad2",
                                                        "_id": "645150e756c86353b6f9cd12"
                                                    },
                                                    "_id": "645150e756c86353b6f9cd11",
                                                    "dataType": "TEXT"
                                                },
                                                "_id": "645150d5da70923acea9c2f4"
                                            }
                                        ],
                                        "maxLength": 4,
                                        "listDataType": "TEXT",
                                        "_id": "6451502e56c86353b6f9c8ed",
                                        "dataType": "LIST"
                                    },
                                    "_id": "6451502e56c86353b6f9c8ec"
                                },
                                "cta": {
                                    "commonName": "Call to Action Button",
                                    "dataProps": {
                                        "textValue": "",
                                        "locationTextValue": "",
                                        "url": "",
                                        "urlRef": {
                                            "linkType": "PAGE_TEMPLATE",
                                            "staticUrl": "",
                                            "pageTemplateId": "",
                                            "_id": "6451502e56c86353b6f9c8ef"
                                        },
                                        "_id": "6451502e56c86353b6f9c8f0",
                                        "dataType": "TEXT"
                                    },
                                    "_id": "6451502e56c86353b6f9c8ee"
                                },
                                "_id": "6451502e56c86353b6f9c8f1",
                                "sectionType": "ImageRow"
                            },
                            "_id": "6451502e56c86353b6f9c8e2"
                        }
                        ]

            }
        };
    }

    LocationSection.$inject = ['CommonUtilService']
    function LocationSection(CommonUtilService) {
        return {
            restrict: "EA",
            scope: {
                // from page template
                locationConfigOverrides: '=',
                locationProps: '=',
                preLink: '=',
                separator: '=',
                currentLocale: '=',
            },
            templateUrl: 'shared/js/widget/pageTemplate/location-section.html',
            link: function($scope){

                $scope.productNameDisplay;
                $scope.chunkedData;

                if($scope.locationProps) {
                    $scope.productNameDisplay = $scope.locationProps.productName.dataProps.textValue;
                }

                var allLocations = _.sortBy(getLocations(), function(location) {
                    return location.province + location.city;
                });
                var filteredLocations = _.filter(allLocations, function(location) {
                    var locationKey = getLocationKey(location);
                    return !location.canonical && location.locale == $scope.currentLocale &&
                        (!$scope.locationConfigOverrides[locationKey] ||
                            !$scope.locationConfigOverrides[locationKey].disabled
                        )
                })
                $scope.chunkedData = chunk(filteredLocations, 1);

                // $scope.$watch("locationProps", function() {
                //     if($scope.locationProps) {
                //         $scope.productNameDisplay = $scope.locationProps.productName.dataProps.textValue;
                //     }
                // }, true)

                // $scope.$watchGroup(["currentLocale", "locationConfigOverrides"], function() {
                // var filteredLocations = _.filter(allLocations, function(location) {
                //     var locationKey = getLocationKey(location);
                //     return !location.canonical && location.locale == $scope.currentLocale &&
                //         (!$scope.locationConfigOverrides[locationKey] ||
                //             !$scope.locationConfigOverrides[locationKey].disabled
                //         )
                // })
                // $scope.chunkedData = chunk(filteredLocations, 1);
                // }, true)

                function chunk(arr, size) {
                    var newArr = [];
                    for (var i=0; i<arr.length; i+=size) {
                        newArr.push(arr.slice(i, i+size));
                    }
                    return newArr;
                }

                $scope.getLink = function(location){
                    var urlOverride = $scope.locationProps.linkUrlOverride.dataProps.textValue;
                    if (urlOverride) {
                        var cityToken = modelService.PAGE_TEMPLATE_LOCATION_TOKENS.LOCATION_CITY;
                        var stateToken = modelService.PAGE_TEMPLATE_LOCATION_TOKENS.LOCATION_STATE;
                        return urlOverride
                            .replace(cityToken, CommonUtilService.replaceSpaceWithHyphensAndLower(location.city))
                            .replace(stateToken, CommonUtilService.replaceSpaceWithHyphensAndLower(location.province))
                    } else {
                        return $scope.preLink + $scope.separator + CommonUtilService.buildUrl(location)
                    }
                }
            }
        }
    }

    function OurCompassSection() {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/our-compass-section.html',
            link: function(){}
        };
    }

    function ClientsSection() {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/clients-section.html',
            link: function(){}
        };
    }

    function BrandsSection(BrandService) {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/brands-section.html',
            link: function($scope) {
                $scope.brands = [
                    'Carhartt', 'Adidas', 'Nike', 'AS Colour', 'Helly Hansen', 'Northface', 'Columbia', 'Herschel Bags',
                    'American Apparel', 'Gildan', 'Champion', 'Tentree', 'Russell Athletics', 'Bella and Canvas'
                ]

                    //['Gildan', 'Fruit of the Loom', 'American Apparel', 'Champion', 'Callaway', 'Roots', 'Tentree', 'Under Armour',
                //'Carhartt', 'Nike', 'Northface', 'Russell Athletics']
                BrandService.getAllBrands()
                    .then(function(allBrands) {
                        $scope.brands = _.map($scope.brands, function(brandName) {
                            return _.find(allBrands, function(brand) {
                                return brand.name === brandName
                            });
                        })
                    })
            }
        };
    }

    function ReviewsSection($timeout, $rootScope) {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/reviews-section.html',
            link: function($scope, element, attrs){

                $scope.isLoading = false;
                $scope.isFinishedLoading = false;
                var containerElement = angular.element(element).find('#reviews-widget-container')[0]
                var reviewTemplate = '<div style="overflow-x: hidden" class="elfsight-app-73d009d3-06ea-4f44-ac62-eaf05d4c02a6" data-elfsight-app-lazy></div>'
                var isLoaded = document.querySelectorAll('script[src="https://static.elfsight.com/platform/platform.js"]');

                var offTop = element.offset().top
                function checkScrollListener() {
                        var scrollPos = $(this).scrollTop()
                        if (!$scope.reviewWidgetTemplate && (Math.abs(scrollPos > (offTop - 2000)))) {
                            $scope.loadScript();
                        }
                    }

                $scope.loadScript = function () {
                    if ($scope.isLoading) {
                        return;
                    } else {
                        $scope.isLoading = true;
                    }

                    var isLoaded = document.querySelectorAll('script[src="https://static.elfsight.com/platform/platform.js"]');
                    // maybe it was loaded from a previous page or from floating widget
                    if (isLoaded.length > 0) {
                        $timeout(function () {
                            $scope.isFinishedLoading = true;
                            $scope.reviewWidgetTemplate = reviewTemplate
                            $scope.$apply();
                        })
                    } else {
                        var myScript = document.createElement("script");
                        myScript.src = 'https://static.elfsight.com/platform/platform.js';
                        document.body.appendChild(myScript);
                        myScript.onload = function () {
                            $timeout(function () {
                                $scope.reviewWidgetTemplate = reviewTemplate
                                $scope.$apply();
                            })
                        }
                    }

                    var fullyLoadedWidgetObserver = new MutationObserver(function(mutation) {
                        fullyLoadedWidgetObserver.disconnect();
                        setTimeout(function () {
                            // console.log('ig widget');
                            $scope.isFinishedLoading = true;
                            $scope.$apply();

                        }, 0)
                    })

                    var containerWidgetObserver = new MutationObserver(function(mutation) {
                        fullyLoadedWidgetObserver.observe(mutation[0].addedNodes[0], {subtree: true, childList:true})
                        containerWidgetObserver.disconnect();
                    });
                    containerWidgetObserver.observe(containerElement, {childList: true});

                    // fallback
                    setTimeout(function() {
                        $scope.isFinishedLoading = true;
                        $scope.$apply();
                    }, 3500)
                }
                window.addEventListener('scroll', checkScrollListener);

                // do not wait for scroll for prerender and load immediately.
                if ($rootScope.isPrerender() || isLoaded.length > 0) {
                    $scope.loadScript();
                }

                $scope.$on('$destroy', function() {
                    window.removeEventListener("scroll", checkScrollListener)
                })
                checkScrollListener();
            }
        };
    }

    function MerchSection($timeout, $rootScope, matchmedia) {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'shared/js/widget/pageTemplate/merch-section.html',
            link: function($scope, element, attrs){
                resizeIg();
                $scope.isLoading = false;
                $scope.isFinishedLoading = false;
                var containerElement = angular.element(element).find('#ig-widget-container')[0]

                var htmlTemplate = '<behold-widget  feed-id="zmH9xVzo5AzUASR5FPmR"></behold-widget>'

                //reserve space - CLS
                function resizeIg() {
                    var isPhone = matchmedia.is("(max-width: 900px)");
                    var numBoxes;
                    var buffer;
                    if (isPhone) {
                        numBoxes = 1
                        buffer = 15
                    } else {
                        numBoxes = 4;
                        buffer = 50;
                    }

                    document.getElementById("ig-container").style.height = ((Math.min(2500, window.innerWidth ) + buffer) / numBoxes) + 'px'
                }

                window.addEventListener('resize', resizeIg);
                $scope.loadScript = function () {
                    if ($scope.isLoading) {
                        return;
                    } else {
                        $scope.isLoading = true;
                    }

                    // maybe it was loaded from a previous page
                    if (isScriptLoaded && isScriptLoaded.length > 0) {
                        $timeout(function () {
                            $scope.igWidgetTemplate = htmlTemplate
                        })
                    } else {
                        var myScript = document.createElement("script");
                        myScript.src = 'https://w.behold.so/widget.js';
                        myScript.type = 'module'
                        myScript.className = 'ig-feed-script';
                        document.body.appendChild(myScript);
                        myScript.onload = function () {
                            $timeout(function () {
                                $scope.igWidgetTemplate = htmlTemplate })
                        }
                    }
                    var fullyLoadedWidgetObserver = new MutationObserver(function() {
                        fullyLoadedWidgetObserver.disconnect();
                        $timeout(function () {
                            // console.log('ig widget');
                            $scope.isFinishedLoading = true;
                        }, 0)
                    })

                    var containerWidgetObserver = new MutationObserver(function(mutation) {
                        fullyLoadedWidgetObserver.observe(mutation[0].addedNodes[0].shadow, {childList:true})
                        containerWidgetObserver.disconnect();
                    });
                    containerWidgetObserver.observe(containerElement, {subtree: true, childList: true});

                    // fallback
                    $timeout(function() {
                        $scope.isFinishedLoading = true;
                    }, 5000)
                }

                // do not wait for scroll for prerender and load immediately.
                if ($rootScope.isPrerender()) {
                    $scope.loadScript();
                }

                // <script type="module" src="https://w.behold.so/widget.js"></script>
                var isScriptLoaded = document.querySelectorAll('.ig-feed-script');
                if (isScriptLoaded.length > 0) {
                    $scope.loadScript();
                } else {
                    window.addEventListener('scroll', checkScrollListener);
                }


                function checkScrollListener() {
                    var offTop = element.offset().top
                    $(window).on("scroll",function() {
                        var scrollPos = $(this).scrollTop()

                        if (!$scope.igWidgetTemplate && (scrollPos > (offTop - 1000))) {
                            $scope.loadScript();
                        }
                    })
                }

                $scope.$on('$destroy', function() {
                    window.removeEventListener("scroll", checkScrollListener)
                    window.removeEventListener('resize', resizeIg)
                })
                checkScrollListener();
            }
        };
    }

    function FAQSection() {
        return {
            restrict: 'EA',
            scope: {
                faqProps: '=',
                fromAdmin: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/faq-section.html',
            link: function($scope){
                $scope.faqList = []

                activate();

                function activate() {
                    getFaqList();
                    // $scope.$watch("faqProps", function() {
                    //     getFaqList();
                    // }, true)
                }

                // console.log($scope.faqProps)

                function getFaqList() {
                    var questions = $scope.faqProps.questions.dataProps.dataTypeList;
                    var answers = $scope.faqProps.answers.dataProps.dataTypeList;

                    $scope.faqList = _.map(questions, function(question, index) {
                        return {
                            question: question,
                            answer: answers[index]
                        }
                    });

                    $scope.faqList[0].visible = true

                    if ($scope.fromAdmin) {
                        _.forEach($scope.faqList, function(faq) {
                            faq.visible = true
                        })
                    }
                }
            }
        };
    }

    function ImageRowSection() {
        return {
            restrict: 'EA',
            scope: {
                isHeading: '=',
                imageProps: '=',
            },
            templateUrl: 'shared/js/widget/pageTemplate/image-row-section.html',
            link: function($scope, elem,attr) {
                $scope.images = [];
                $scope.imageStyle = {};

                function activate() {
                    var images = $scope.imageProps.images.dataProps.dataTypeList;
                    var labels = $scope.imageProps.labels.dataProps.dataTypeList;

                    $scope.images = _.map(images, function(image, index) {
                        var label = labels[index];
                        if (label) {
                            if (!label.dataProps.url && image.dataProps.imageHyperLink) {
                                label.dataProps.url = image.dataProps.imageHyperLink
                            }
                        }
                        return {
                            image: image,
                            label: label
                        }
                    });

                    var hasPadding = $scope.imageProps.hasPadding.dataProps.booleanValue;
                    var style = {}
                    if (hasPadding) {
                        style.padding = "1%"
                    }

                    var fitWidth = $scope.imageProps.fitWidth.dataProps.booleanValue;
                    if (fitWidth) {
                        var numImages = $scope.imageProps.images.dataProps.dataTypeList.length;
                        var width;
                        if (numImages) {
                            width = (100 / numImages).toFixed(0);
                            style.width = width + "%"
                        }
                    }
                    $scope.imageStyle = style;

                    // $scope.$watch('imageProps', function() {
                    //
                    // }, true)
                }
                activate();
            }
        };
    }

    function LeftColumnSection() {
        return {
            restrict: 'EA',
            scope: {
                leftColumnProps: '=',
                isHeading: '=',
            },
            templateUrl: 'shared/js/widget/pageTemplate/left-column-section.html',
            link: function(){}
        };
    }

    function RightColumnSection() {
        return {
            restrict: 'EA',
            scope: {
                rightColumnProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/right-column-section.html',
            link: function(){}
        };
    }


    ProductListSection.$inject = ['ProductService', '$location', 'matchmedia']
    function ProductListSection(ProductService, $location, matchmedia) {
        return {
            restrict: 'EA',
            scope: {
                isHeading: '=',
                productListProps: '='
            },
            templateUrl: 'shared/js/widget/pageTemplate/product-list-section.html',
            link: function($scope){
                $scope.fetchedProducts;
                $scope.previewProducts;
                $scope.roundedLength;
                $scope.props = {
                    showFilters: null,
                    hideWidget: null,
                    hideWidgetTitle: null,
                    hideWidgetCta: null
                }

                $scope.previewLength = 3;
                $scope.watcher = []
                $scope.watcher.push(matchmedia.onTablet(function(mediaQuery) {
                    if(mediaQuery.matches) {
                        $scope.previewLength = 2;
                    }
                }));

                function activate() {
                    $scope.fetchProducts();

                    $scope.$on('productsLoaded', function(){
                        $scope.productsLoaded = true;
                    })

                    // show on query param
                    var shouldViewAllOverride = !_.isUndefined($location.search().viewAll);
                    if (shouldViewAllOverride) {
                        $scope.props.hideWidget = false;
                    }
                    if (!shouldViewAllOverride) {
                        $scope.props.hideWidget = $scope.productListProps.hideList.dataProps.booleanValue;
                    }
                    // $scope.props.hideWidget = $scope.productListProps.hideList.dataProps.booleanValue;
                    $scope.props.hideWidgetTitle = $scope.productListProps.hideListTitle.dataProps.textValue ?
                        $scope.productListProps.hideListTitle.dataProps.textValue :
                        "Can't Find Your Item?";
                    $scope.props.hideWidgetCta = $scope.productListProps.hideListCta.dataProps.textValue ?
                        $scope.productListProps.hideListCta.dataProps.textValue :
                        "Browse all Items!";

                    // $scope.$watch('productListProps.productsQuery', function() {
                    //     $scope.fetchedProducts = undefined;
                    //     $scope.fetchProducts();
                    // }, true)
                    //
                    // $scope.$watch('productListProps', function() {
                    // }, true)

                    $scope.$on('$destroy', function() {
                        _.forEach($scope.watchers, function(watcher){
                            watcher();
                        })
                    })
                }


                $scope.fetchProducts = function() {
                    let productFilters = $scope.productListProps.productsQuery.dataProps;
                    var filterRequest = _.omit(productFilters, ['_id', 'dataType'])
                    return ProductService.fetchProducts(filterRequest)
                        .then(function(fetchedProducts) {
                            $scope.fetchedProducts = fetchedProducts;
                            $scope.previewProducts = fetchedProducts.slice(0, Math.min($scope.previewLength, fetchedProducts.length));
                        })
                        .catch(function() {
                            $scope.fetchedProducts = [];
                            $scope.previewProducts = [];
                        })
                        .finally(function(){
                            $scope.roundedLength = Math.ceil(($scope.fetchedProducts.length + 1) / 10) * 10;
                            $scope.props.showFilters = $scope.fetchedProducts.length > 8;
                        })
                }

                $scope.toggleViewAll = function() {
                    $location.search('viewAll', true);
                }

                activate();
            }
        };
    }
})();