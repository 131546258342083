(function() {
    'use strict';

    angular.module('app')
        .factory('PageTemplateService', PageTemplateService)

    PageTemplateService.$inject = ['$http', '$q', 'CountryService'];

    function PageTemplateService($http, $q, CountryService) {
        function findPageTemplateByUrl(url, locale, viewToken) {
            return $http.get('/api/pageTemplates/find', {params: {url: url, locale: locale, viewToken: viewToken}})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function getAllLivePageTemplates(locale) {
            return $http.get('/api/pageTemplates/', {params: {locale: locale}})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function getBreadcrumb(url) {
            return $http.get('/api/pageTemplates/breadcrumb', {params: {url: url, locale: CountryService.getCurrentLocale()}})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function cleanPageName(targetKeyword) {
            //1. remove instances of word custom
            targetKeyword = targetKeyword.replace(' Custom ', ' ');
            targetKeyword = targetKeyword.replace('Custom ', '');

            return targetKeyword;
        }


        return {
            findPageTemplateByUrl: findPageTemplateByUrl,
            getBreadcrumb: getBreadcrumb,
            getAllLivePageTemplates: getAllLivePageTemplates,
            cleanPageName: cleanPageName
        };
    }

})();
