(function() {
    'use strict';

    angular.module('app')
        .factory('CustomerService', CustomerService)

    CustomerService.$inject = ['$http', '$q', '$rootScope', 'AnalyticsService', 'CountryService'];

    function CustomerService($http, $q, $rootScope, AnalyticsService, CountryService) {

        function getOrders() {
            return $http.get('/api/customers/orders')
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }
        function getDesigns() {
            return $http.get('/api/customers/designs')
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function getUploads() {
            return $http.get('/api/customers/uploads')
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }


        function updateEmail(updatedEmail) {
            return $http.patch('/api/customers/email', {username: updatedEmail})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function updateCart(updatedCart) {
            return $http.patch('/api/customers/currentCart', {currentCart: updatedCart})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function updatePassword(updatedPassword) {
            return $http.patch('/api/customers/password', {password: updatedPassword})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function updateProfile(profileObject) {
            return $http.patch('/api/customers/profile', profileObject)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function updateShipping(shippingAddress) {
            return $http.patch('/api/customers/shippingAddress', {shippingAddress: shippingAddress})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function updateBilling(billingAddress) {
            return $http.patch('/api/customers/billingAddress', {billingAddress: billingAddress})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function updateBlindPackaging(isBlindPackaging) {
            return $http.patch('/api/customers/blindPackaging', {blindPackaging: isBlindPackaging})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        /**
         * Copy line items from specified order number
         * @param orderNumber
         * @param cartId - if provided, means that we copy to the specified cart. if null, create new cart.
         * @return copied line items (no qtys)
         */
        function copyOrder(orderNumber, cartId) {
            return $http.post('/api/customers/copyOrderItems/' + orderNumber, {cartId: cartId})
                .then(function (response) {
                    return response.data;
                })
                .catch(function (err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function getQuoteItems(orderNumber, quoteId) {
            return $http.get('/api/customers/getQuote', {
                params: {
                    orderNumber: orderNumber, quoteId: quoteId
                }
            })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (err) {
                    return $q.reject(err.data);
                })
        }

        function getCart(cartId) {
            return $http.get('/api/customers/getCart/' + cartId)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (err) {
                    showError(err.data.message);
                    return $q.resolve({
                        cart: {
                            _id: cartId,
                            listOfItems: []
                        },
                        invalidItems: [],
                        products: []
                    });
                })
        }

        function searchKeyword(searchKeyword, isPreview) {
            $rootScope.sentTrackingEvent("query","search", searchKeyword);
            // GA4 SEARCH https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#search
            AnalyticsService.trackSearchEvent(searchKeyword);
            return $http.get('/api/customers/globalSearchKeyword',
                {
                    params: {
                        searchText: searchKeyword,
                        isPreview: isPreview,
                        locale: CountryService.getCurrentLocale()
                    }
                })
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    if (err.data.message) {
                        showError(err.data.message);
                    } else {
                        showError();
                    }
                    return $q.reject(err);
                })
        }

        return {
            updateEmail: updateEmail,
            updatePassword: updatePassword,
            updateCart : updateCart,
            updateProfile: updateProfile,
            updateShipping: updateShipping,
            updateBilling: updateBilling,
            updateBlindPackaging: updateBlindPackaging,
            copyOrder: copyOrder,
            getCart: getCart,
            getQuoteItems: getQuoteItems,
            searchKeyword: searchKeyword,
            getOrders: getOrders,
            getDesigns: getDesigns,
            getUploads: getUploads
        };
    }

})();
